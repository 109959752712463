<template>
  <ListItemsWithTableLayout>
    <template #body>
      <HandlerAccessDenied :is-denied="isDenied">
        <EstimationSettingsFilterForm
          :isFilterEnabled="isFilterEnabled"
          @toggle-filter="isFilterEnabled = !isFilterEnabled"
          @set-filter="setFilter"
        ></EstimationSettingsFilterForm>
        <TopListEstimationSettingsPanel
          @set-common-filter="setCommonFilter"
          @toggle-filter="isFilterEnabled = !isFilterEnabled"
          :isFilterEnabled="isFilterEnabled"
          :isFilterSetted="isFilterSetted"
          @update-table="updateEstimationSettingsList"
        ></TopListEstimationSettingsPanel>
        <div
          id="table-container"
          class="table-container flex flex-col"
          :class="[
            filteredEstimationSettings && filteredEstimationSettings.length == 0
              ? 'empty'
              : 'filled',
          ]"
        >
          <EstimationSettingsTable
            :estimations-settings="filteredEstimationSettings"
            :isActiveFirst="activeFirstFromStore"
            :isLoading="isLoading"
            :rowsCount="rowsCount(43) * tableDisplaySettings.first"
            @sort-table="sortTable"
            @update-table="updateEstimationSettingsList"
          ></EstimationSettingsTable>
          <EmptyTableMessage
            v-if="
              filteredEstimationSettings &&
              filteredEstimationSettings.length == 0 &&
              !isLoading
            "
          >
            <template #text>
              Примените другой фильтр или создайте первые настройки. <br />
              Для этого кликните на кнопку "Добавить строку".
            </template>
          </EmptyTableMessage>
        </div>
        <Paginator
          v-model:first="tableDisplaySettings.first"
          @update:first="updateEstimationSettingsList"
          :rows="1"
          class="s-paginator"
          :totalRecords="store.estimationsPagesCount"
          template="PrevPageLink CurrentPageReport NextPageLink"
          currentPageReportTemplate="{first} из {totalRecords}"
        />
      </HandlerAccessDenied>
    </template>
  </ListItemsWithTableLayout>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";

import Paginator from "primevue/paginator";
import ListItemsWithTableLayout from "@/widgets/layouts/ListItemsWithTableLayout.vue";
import TopListEstimationSettingsPanel from "@/entities/analytics/estimation_settings/panels/TopListEstimationSettingsPanel.vue";
import EmptyTableMessage from "@/entities/segments/EmptyTableMessage.vue";
import HandlerAccessDenied from "@/shared/base/HandlerAccessDenied.vue";
import EstimationSettingsTable from "@/entities/analytics/estimation_settings/EstimationSettingsTable.vue";
import EstimationSettingsFilterForm from "@/entities/analytics/estimation_settings/forms/EstimationSettingsFilterForm.vue";

import {
  EstimationSettingsParams,
  useEstimationsSettingsStore,
} from "@/store/analytics/estimation_settings";
import { useAuthStore } from "@/store/auth/login";
import { rowsCount } from "@/services/utils/Pagination";
import { setEstimationSettingsParamsByFilter } from "@/services/analytics/estimation_settings";
import { FilterForm } from "@/services/analytics/estimation_settings";

const store = useEstimationsSettingsStore();
const authStore = useAuthStore();
const isLoading = ref(false);
const isFilterEnabled = ref(false);
const isFilterSetted = ref(false);
const tableDisplaySettings = ref({
  first: 0,
  orderField: "",
  commonFilter: "",
  filter: {} as FilterForm,
});

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(["system:edit"], ["analytics:edit"]);
});

const filteredEstimationSettings = computed(() => {
  return store.estimationSettingsList;
});

const activeFirstFromStore = computed(() => store.activeFirst);

onMounted(() => {
  loadData();
});

const loadData = () => {
  isLoading.value = true;
  updateEstimationSettingsList();
};

const setCommonFilter = (filter: string) => {
  tableDisplaySettings.value.commonFilter = filter;
  updateEstimationSettingsList();
};

const sortTable = (order_by: string) => {
  tableDisplaySettings.value.orderField = order_by;
  updateEstimationSettingsList();
};

const setFilter = (filter: FilterForm, isSetted: boolean) => {
  tableDisplaySettings.value.filter = filter;
  isFilterSetted.value = isSetted;
  updateEstimationSettingsList();
};

const updateEstimationSettingsList = () => {
  let isEnded = false;
  setTimeout(() => {
    if (!isEnded) {
      isLoading.value = true;
    }
  }, 200);
  const params: EstimationSettingsParams = {
    page: tableDisplaySettings.value.first + 1,
    per_page: rowsCount(43, 20),
  };

  setEstimationSettingsParamsByFilter(params, tableDisplaySettings.value);

  store.getEstimationSettings(params).finally(() => {
    isLoading.value = false;
    isEnded = true;
    setTimeout(() => {
      store.activeFirst = false;
    }, 5000);
  });
};
</script>
