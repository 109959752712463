<template>
  <DataTable
    :value="data"
    class="scroll-table-y main-table scrollbar-custom no-padding"
    :class="[data && data.length == 0 ? 'empty-table' : '']"
    stripedRows
    scrollable
    :loading="isLoading"
  >
    <Column field="number" header="№" style="width: 38px">
      <template #body="slotProps">
        <div class="slot-padding">{{ slotProps.index + 1 }}</div>
      </template>
    </Column>
    <Column field="department" header="Подразделение">
      <template #body="slotProps">
        <div class="slot-padding">{{ slotProps.data["department"] }}</div>
      </template>
    </Column>
    <Column field="role" header="Должность">
      <template #body="slotProps">
        <div class="slot-padding">{{ slotProps.data["role"] }}</div>
      </template>
    </Column>
    <Column
      v-for="col of columns"
      :key="col.field"
      :field="col.field"
      :header="col.header"
    >
      <template #body="slotProps">
        <Dropdown
          v-model="slotProps.data[slotProps.field].name"
          :options="permissionsStore.permissionsList"
          optionLabel="display_name"
          optionValue="name"
          panelClass="panel-drop-right"
          class="drop-right"
          :disabled="isDenied"
          @change="onPermissionChange(slotProps.data[slotProps.field])"
        >
          <template #value="slotPropsOption">
            <div class="option" :class="slotPropsOption.value">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                class="circle"
              >
                <circle cx="4" cy="4" r="4" fill="#eeeeee" />
              </svg>
              {{
                permissionsStore.permissionMap.get(slotPropsOption.value)?.name
              }}
            </div>
          </template>
          <template #option="slotPropsOption">
            <div class="option" :class="slotPropsOption.option.name">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                class="circle"
              >
                <circle cx="4" cy="4" r="4" fill="#eeeeee" />
              </svg>
              {{ slotPropsOption.option.display_name }}
            </div>
          </template>
        </Dropdown>
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";

import { Organization } from "@/store/dictionaries/organizations";
import { usePermissionsStore, RoleDescription } from "@/store/auth/permissions";
import { rootGetters } from "@/main";
import { useAuthStore } from "@/store/auth/login";

const permissionsStore = usePermissionsStore();
const authStore = useAuthStore();
const props = defineProps<{
  organization: Organization | null;
  isLoading: boolean;
  rowsCount?: number;
}>();

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(["settings:edit", "system:edit"]);
});

const emits = defineEmits(["setLoading"]);
const columns = ref<ColumnPattern[]>([]);
const data = ref<any[]>([]);

const setData = () => {
  const rolesArray = [] as any[];
  props.organization?.departments.forEach((dep) => {
    dep.roles?.forEach((role) => {
      const tableStroke: any = { department: dep.name, role: role.name };

      permissionsStore.entitiesList.forEach((entity) => {
        if (
          permissionsStore.rolesPermissionsMap.has(`${role.id}_${entity.name}`)
        )
          tableStroke[entity.name] = permissionsStore.rolesPermissionsMap.get(
            `${role.id}_${entity.name}`
          );
        else
          tableStroke[entity.name] = {
            id: 0,
            name: "empty",
            role_id: role.id,
            entity_id: entity.id,
          };
      });

      rolesArray.push(tableStroke);
    });
  });
  data.value = rolesArray;
};

watch(
  () => props.organization,
  () => {
    setData();
  }
);

const loadData = () => {
  Promise.all([
    permissionsStore.getEntities(),
    permissionsStore.getRolesPermissions(),
    permissionsStore.getPermissions(),
  ]).then(() => {
    columns.value = [];
    permissionsStore.entitiesList.forEach((entity) => {
      columns.value.push({ field: entity.name, header: entity.display_name });
    });
    setData();
  });
};

onMounted(() => {
  loadData();
});

const onPermissionChange = (permission: RoleDescription) => {
  if (permission.id != 0 && permission.name == "empty") {
    permissionsStore.deletePermission(permission.id).then(() => {
      permission.id = 0;
    });
  } else {
    const permission_id = permissionsStore.permissionMap.get(permission.name);
    if (permission_id)
      permissionsStore
        .createRolePermission({
          entity_id: permission.entity_id,
          role_id: permission.role_id,
          permission_id: permission_id.id,
        })
        .then((data) => {
          if (data && data.id) {
            permission.id = data.id;
          }
        })
        .catch((err) => {
          if (err.response && err.response.status == 403)
            rootGetters.toast.show("warn", "Ошибка", "Нет доступа");
        });
  }
};

interface ColumnPattern {
  field: string;
  header: string;
}
</script>

<style lang="scss">
.p-datatable.no-padding .p-datatable-tbody > tr > td {
  padding: 0;
}

.slot-padding {
  padding: 0 1rem;
}

.p-dropdown.drop-right {
  background: transparent;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  box-shadow: none;
}

.p-dropdown.drop-right .p-dropdown-trigger {
  display: none;
}

.p-dropdown-panel.panel-drop-right
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background: #f7f7fd;
}

.option {
  padding: 4px 8px;
  border-radius: 8px;

  .circle {
    margin-right: 8px;
  }

  &.denied {
    background-color: #fff1f4;
    color: #cd4364;

    .circle circle {
      fill: #cd4364;
    }
  }

  &.empty {
    background-color: #efefef;
    color: #a19cab;

    .circle circle {
      fill: #a19cab;
    }
  }

  &.edit {
    background-color: #f2f1ff;
    color: #5f63e0;

    .circle circle {
      fill: #5f63e0;
    }
  }

  &.read {
    background-color: #f0f8f5;
    color: #029382;

    .circle circle {
      fill: #029382;
    }
  }
}
</style>
