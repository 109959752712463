<template>
  <div class="form-filter">
    <div class="form-block">
      <div class="form-block-title">
        Создание наименования рекламной кампании
      </div>

      <div class="flex some-inputs">
        <div class="flex flex-col m-r-8 input-container">
          <label class="small-label">Бренд</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataSingleInput
              selectType="brand"
              :is-invalid="isInvalidForm && campaign.brand == null"
              :isSegmentSetted="false"
              :modelValue="campaign.brand"
              @update:modelValue="($event) => (campaign.brand = $event)"
            ></CatalogDataSingleInput>
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Магазин</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataSingleInput
              selectType="shop"
              :is-invalid="isInvalidForm && campaign.shop == null"
              :isSegmentSetted="false"
              :modelValue="campaign.shop"
              @update:modelValue="($event) => (campaign.shop = $event)"
            ></CatalogDataSingleInput>
          </SceletonInput>
        </div>
      </div>

      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-8 input-container">
          <label class="small-label">Стратегия</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputText
              placeholder="Введите значение"
              v-model="campaign.strategy"
              :disabled="false"
            />
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Дополнительное поле</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputText
              placeholder="Введите значение"
              v-model="campaign.extra_info"
              :disabled="false"
            />
          </SceletonInput>
        </div>
      </div>

      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-8 input-container">
          <label class="small-label"
            >Период запуска рекламной кампании от</label
          >
          <SceletonInput :is-loading="isLoadingData">
            <CalendarBaseInput
              :modelValue="campaign.date_from"
              :is-invalid="isInvalidForm && campaign.date_from == null"
              @update:modelValue="($event) => (campaign.date_from = $event)"
              :disabled="false"
            ></CalendarBaseInput>
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label"
            >Период запуска рекламной кампании до</label
          >
          <SceletonInput :is-loading="isLoadingData">
            <CalendarBaseInput
              :min-date="new Date()"
              :modelValue="campaign.date_to"
              :is-invalid="isInvalidForm && campaign.date_to == null"
              @update:modelValue="($event) => (campaign.date_to = $event)"
              :disabled="false"
            ></CalendarBaseInput>
          </SceletonInput>
        </div>
      </div>

      <div class="flex flex-col fl-1 m-t-8">
        <label class="small-label">Название рекламной кампании</label>
        <SceletonInput :is-loading="isLoadingData">
          <IconField>
            <InputIcon
              class="pi"
              :class="
                isNameFilled
                  ? 'pi-check-circle violete'
                  : 'pi-exclamation-circle red'
              "
              v-tooltip="{
                value: getMessage(isNameFilled),
                class: 'white-tooltip',
              }"
            >
            </InputIcon>
            <InputText
              class="campaign-name"
              placeholder="Введите название"
              v-model="campaignName"
              :disabled="true"
            />
          </IconField>
        </SceletonInput>
      </div>
    </div>

    <div
      class="form-block"
      v-for="(plan, idx) in campaign.traffic_plans"
      :key="`traffic-plans-${idx}`"
      :class="[idx != campaign.traffic_plans.length - 1 ? 'hidden' : '']"
    >
      <div class="form-block-title flex fl-jc-sb">
        <div>План трафика</div>
        <div class="flex">
          <div class="m-r-12">
            <span class="label-name">CTR план:</span>
            {{
              roundToTwoDecimalPlaces(
                calcCTR(plan.plan_click, plan.plan_impressions)
              )
            }}
            %
          </div>
          <div class="m-r-12">
            <span class="label-name">CPC план: </span>
            {{
              roundToTwoDecimalPlaces(calcCPC(plan.plan_click, plan.plan_cost))
            }}
            ₽
          </div>
          <div>
            <span class="label-name">CPM план: </span>
            {{
              roundToTwoDecimalPlaces(
                calcCPM(plan.plan_impressions, plan.plan_cost)
              )
            }}
            ₽
          </div>
        </div>
      </div>

      <div class="flex some-inputs">
        <div class="flex flex-col fl-1">
          <label class="small-label">Рекламная площадка</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataSingleInput
              :not-search="true"
              :use-id-only="true"
              selectType="platform"
              :is-invalid="isInvalidForm && plan.platform_slug == null"
              :isSegmentSetted="false"
              :modelValue="plan.platform_slug"
              @update:modelValue="($event) => (plan.platform_slug = $event)"
              filter-field="slug"
              :filter-list="platforms"
            ></CatalogDataSingleInput>
          </SceletonInput>
        </div>
      </div>

      <div class="flex fl-1 m-t-8 flex-end">
        <div class="flex flex-col fl-1 m-r-12">
          <label for="name" class="small-label">Показы план</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputNumber
              placeholder="Введите значение"
              v-model="plan.plan_impressions"
              :maxFractionDigits="2"
              :invalid="isInvalidForm && plan.plan_impressions == null"
            />
          </SceletonInput>
        </div>

        <div class="flex flex-col fl-1 m-r-12">
          <label for="name" class="small-label">Клики план</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputNumber
              placeholder="Введите значение"
              v-model="plan.plan_click"
              :maxFractionDigits="2"
              :invalid="isInvalidForm && plan.plan_impressions == null"
            />
          </SceletonInput>
        </div>

        <div class="flex flex-col fl-1 m-r-12">
          <label for="name" class="small-label">Бюджет план (без НДС), ₽</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputNumber
              placeholder="Введите значение"
              v-model="plan.plan_cost"
              :maxFractionDigits="2"
              :invalid="isInvalidForm && plan.plan_impressions == null"
            />
          </SceletonInput>
        </div>

        <div class="flex flex-col fl-1 m-r-12">
          <label for="name" class="small-label">ROAS план</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputNumber
              placeholder="Введите значение"
              v-model="plan.plan_reach"
              :maxFractionDigits="2"
              :invalid="isInvalidForm && plan.plan_impressions == null"
            />
          </SceletonInput>
        </div>

        <div class="group-button-container flex">
          <div
            v-if="campaign.traffic_plans.length > 1"
            class="delete-button flex flex-center"
            @click="deletePlan(idx)"
          >
            <span class="pi pi-trash"></span>
          </div>
          <div
            class="group-button flex flex-center"
            @click="addPlan(idx)"
            v-if="
              platformsStore.itemList.length > campaign.traffic_plans.length
            "
          >
            <span class="pi pi-plus"></span>
            <div class="p-l-10">Группа</div>
          </div>
        </div>
      </div>
    </div>
    <DuplicatedTraficPlanDialog
      :id="duplicatedId"
      :visible="confirmVisible.duplicate"
      @close="confirmVisible.duplicate = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import CatalogDataSingleInput from "@/widgets/forms/CatalogDataSingleInput.vue";
import CalendarBaseInput from "@/shared/forms/CalendarBaseInput.vue";
import SceletonInput from "@/shared/forms/SceletonInput.vue";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import DuplicatedTraficPlanDialog from "@/shared/dialogs/DuplicatedTraficPlanDialog.vue";

import { Brand } from "@/store/catalog_data/brands";
import { Shop } from "@/store/catalog_data/shops";
import { useCampaignStore } from "@/store/campaigns/campaigns";
import { usePlatformsDictionaryStore } from "@/store/catalog_data/platforms";
import {
  getCampaignName,
  isCampaignNameValidated,
  isTraficPlanFilled,
  prepareModelWithTraficToServer,
  convertServerToModelWithTraficPlan,
  calcCPC,
  calcCPM,
  calcCTR,
  roundToTwoDecimalPlaces,
  hasUniquePlatformSlugs,
} from "@/services/campaigns/utils";
import { rootGetters } from "@/main";

const emit = defineEmits(["setCreateButton", "setLoading"]);
defineProps<{
  isLoading: boolean;
}>();

const route = useRoute();
const router = useRouter();
const store = useCampaignStore();
const platformsStore = usePlatformsDictionaryStore();
const isNameFilled = ref(false);
const isInvalidForm = ref(false);
const confirmVisible = ref({
  duplicate: false,
});
const isLoadingData = ref(false);

const duplicatedId = ref(0);
const useNameDefault = ref(false);
const nameDefault = ref(null);
const isLoadedFirst = ref(true);

const campaign = ref({
  brand: null as Brand | null,
  shop: null as Shop | null,
  name: "",
  strategy: "",
  extra_info: "",
  date_from: null as Date | null,
  date_to: null as Date | null,
  traffic_plans: [
    {
      plan_click: null as number | null,
      plan_impressions: null as number | null,
      plan_reach: null as number | null,
      plan_cost: null as number | null,
      platform_slug: null as string | null,
    },
  ],
});

const campaignWatchFields = computed(() => ({
  brand: campaign.value.brand,
  shop: campaign.value.shop,
  name: campaign.value.name,
  strategy: campaign.value.strategy,
  extra_info: campaign.value.extra_info,
  date_from: campaign.value.date_from,
  date_to: campaign.value.date_to,
}));

const isCampaignSetted = computed(() => {
  return Boolean(route.params.id);
});

const campaignName = computed(() => {
  return getCampaignName(campaign.value, useNameDefault.value);
});

const platforms = computed(() => {
  const platforms: string[] = [];
  campaign.value.traffic_plans.forEach((el) => {
    if (el.platform_slug && !platforms.includes(el.platform_slug)) {
      platforms.push(el.platform_slug);
    }
  });
  return platforms;
});

const reload = () => {
  if (isCampaignSetted.value || store.duplicateCampaignId) {
    isLoadingData.value = true;
    const campaignId = parseInt(route.params.id) || store.duplicateCampaignId;
    store
      .getCampaign(campaignId)
      .then((campaignClone) => {
        if (campaignClone) {
          campaign.value = convertServerToModelWithTraficPlan(campaignClone);
          if (store.addedTraficPlans.length > 0 && route.query.union == "t") {
            campaign.value.traffic_plans = campaign.value.traffic_plans.concat(
              store.addedTraficPlans
            );
          }
          store.addedTraficPlans = [];
          useNameDefault.value = true;
          isLoadedFirst.value = true;
          nameDefault.value = JSON.parse(JSON.stringify(campaign.value));
        }
        if (store.duplicateCampaignId) {
          const extraValue = campaign.value.extra_info
            ? campaign.value.extra_info
            : "";
          campaign.value.extra_info = extraValue + "копия";
          store.duplicateCampaignId = 0;
        }
      })
      .finally(() => {
        isLoadingData.value = false;
      });
  }
};

onMounted(() => {
  reload();
});

watch(
  () => campaignName.value,
  () => {
    isNameFilled.value = isCampaignNameValidated(campaign.value);
  }
);

watch(
  () => route.params.id,
  () => {
    reload();
  }
);

watch(campaignWatchFields, (newValue) => {
  if (nameDefault.value) {
    if (
      (!isLoadedFirst.value || store.duplicateCampaignId) &&
      useNameDefault.value &&
      (newValue.extra_info !== nameDefault.value.extra_info ||
        newValue.brand !== nameDefault.value.brand ||
        newValue.shop !== nameDefault.value.shop ||
        newValue.strategy !== nameDefault.value.strategy ||
        newValue.date_from !== nameDefault.value.date_from ||
        newValue.date_to !== nameDefault.value.date_to)
    ) {
      useNameDefault.value = false;
    }
  }
  if (useNameDefault.value != undefined) isLoadedFirst.value = false;
});

const addPlan = (idx: number) => {
  campaign.value.traffic_plans.splice(idx + 1, 0, {
    plan_click: null as number | null,
    plan_impressions: null as number | null,
    plan_reach: null as number | null,
    plan_cost: null as number | null,
    platform_slug: null as string | null,
  });
};

const deletePlan = (idx: number) => {
  campaign.value.traffic_plans.splice(idx, 1);
};

const createTraficPlan = () => {
  if (!isNameFilled.value || !isTraficPlanFilled(campaign.value)) {
    isInvalidForm.value = true;
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Проверьте, что все необходимые поля заполнены и наименования бренда и магазина не содержат нижнее подчеркивание.`
    );
    return;
  } else if (!hasUniquePlatformSlugs(campaign.value)) {
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Нельзя создавать несколько планов для одной и той же платформы. Необходимо убрать повторяющиеся платформы из списка.`
    );
    return;
  }
  campaign.value.name = campaignName.value;
  const campaignForCreation = prepareModelWithTraficToServer(campaign.value);
  if (campaignForCreation) {
    emit("setLoading", true);
    if (isCampaignSetted.value) {
      performCampaignAction(() =>
        store.updateCampaignWithTraficPlan(
          campaignForCreation,
          parseInt(route.params.id)
        )
      );
    } else {
      performCampaignAction(() =>
        store.createCampaignWithTraficPlan(campaignForCreation)
      );
    }
  }
};

const performCampaignAction = (action: any) => {
  const handleCampaignSuccess = () => {
    router.push({ name: "traficPlanList" });
    setTimeout(() => {
      store.activeFirst = false;
    }, 5000);
  };

  const handleCampaignError = (err: any) => {
    if (err.response && err.response.status == 409)
      store.getCampaignByName(campaign.value.name).then((id) => {
        if (id) {
          duplicatedId.value = id;
          confirmVisible.value.duplicate = true;
          store.addedTraficPlans = campaign.value.traffic_plans;
        }
      });
  };

  action()
    .then(handleCampaignSuccess)
    .catch(handleCampaignError)
    .finally(() => {
      emit("setLoading", false);
    });
};

const getMessage = (isNameFilled: boolean) => {
  if (isNameFilled) return "Наименование рекламной кампании корректно";
  return "Наименование рекламной кампании некорректно";
};

defineExpose({
  createTraficPlan,
});
</script>

<style lang="scss" scoped>
.form-filter {
  max-width: 100%;
  width: 100%;
  padding-bottom: 40px;
  min-width: 780px;
}

.form-block {
  transition: all 3s ease-in;
}

.form-block-title {
  margin-bottom: 12px;
}

.some-inputs {
  max-width: 100%;
}

.input-container {
  max-width: calc(50% - 4px);
  width: calc(50% - 4px);
}

.small-label {
  color: #a19cab;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.43rem;
  padding-bottom: 4px;
  padding-left: 4px;
}

.label-name {
  color: #a19cab;
}

label.locked {
  color: #a19cab;
  cursor: default;
}

:deep(.p-inputtext.p-component) {
  width: 100%;
}

:deep(.p-inputtext:disabled),
:deep(.p-dropdown.p-disabled),
:deep(.p-multiselect.p-disabled) {
  background-color: $page-background-color;
}

.p-inputtext.campaign-name:disabled {
  background-color: #f7f7fd;
  color: $main-font-color;
}

.group-button-container {
  justify-content: flex-end;
  margin-top: 12px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s, outline-color 0.2s;
  height: 34px;
  border-bottom: 1px solid transparent;

  .delete-button {
    background-color: #fff;
    padding: 8px 8px;
    border-radius: 6px;
    margin-right: 12px;
    cursor: pointer;

    &:hover {
      background-color: #fafaff;
    }
  }

  .group-button {
    background-color: #dbdaf9;
    border-radius: 5px;
    padding: 7px 14px;
    cursor: pointer;

    &:hover {
      background-color: #d4d3f8;

      outline-color: transparent;
    }
  }
}

.p-icon-field-right > .p-input-icon:last-of-type.violete {
  color: #6b69e8;
}

.p-icon-field-right > .p-input-icon:last-of-type.red {
  color: #cd4364;
}
</style>
