import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import ListSegmentsPage from "../pages/segments/ListSegmentsPage.vue";
import ListCampaignsPage from "../pages/campaigns/ListCampaignsPage.vue";
import SegmentPage from "../pages/segments/SegmentPage.vue";
import CampaignPage from "@/pages/campaigns/CampaignPage.vue";

import LoginPage from "../pages/auth/LoginPage.vue";
import PrivacyPolicyPage from "../pages/auth/PrivacyPolicyPage.vue";
import PersonalDataPage from "../pages/auth/PersonalDataPage.vue";
import PersonalAccountPage from "../pages/auth/PersonalAccountPage.vue";
import ChangePasswordPage from "@/pages/auth/ChangePasswordPage.vue";
import RecoveryPasswordPage from "@/pages/auth/RecoveryPasswordPage.vue";

import OrganizationsPage from "@/pages/dictionaries/OrganizationsPage.vue";
import OrganizationPage from "@/pages/dictionaries/OrganizationPage.vue";
import UserPage from "@/pages/dictionaries/UserPage.vue";
import UsersPage from "@/pages/dictionaries/UsersPage.vue";
import SettingsPage from "@/pages/settings/SettingsPage.vue";
import AdsCabinetsPage from "@/pages/dictionaries/AdsCabinetsPage.vue";
import AdsCabinetPage from "@/pages/dictionaries/AdsCabinetPage.vue";
import PlatformPage from "@/pages/dictionaries/PlatformPage.vue";
import PlatformsPage from "@/pages/dictionaries/PlatformsPage.vue";
import EstimationParamPage from "@/pages/dictionaries/EstimationParamPage.vue";
import EstimationParamsPage from "@/pages/dictionaries/EstimationParamsPage.vue";

import TraficPlanPage from "@/pages/analytics/TraficPlanPage.vue";
import ListTraficPlansPage from "@/pages/analytics/ListTraficPlansPage.vue";
import EstimationSettingsPage from "@/pages/analytics/EstimationSettingsPage.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/segments"
  },
  {
    path: "/segments",
    name: "listSegments",
    component: ListSegmentsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/segments/:id",
    name: "segment",
    component: SegmentPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/segments/create",
    name: "segmentCreate",
    component: SegmentPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: PrivacyPolicyPage,
  },
  {
    path: "/personal-data-policy",
    name: "personalDataPolicy",
    component: PersonalDataPage,
  },
  {
    path: "/campaigns",
    name: "listCampaigns",
    component: ListCampaignsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaigns/:id",
    name: "campaign",
    component: CampaignPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaigns/create",
    name: "campaignCreate",
    component: CampaignPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/organizations",
    name: "listOrganizations",
    component: OrganizationsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/users",
    name: "users",
    component: UsersPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/ads-cabinets",
    name: "adsCabinets",
    component: AdsCabinetsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/ads-cabinets/create",
    name: "adsCabinetCreate",
    component: AdsCabinetPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/ads-cabinets/:id",
    name: "adsCabinet",
    component: AdsCabinetPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/organizations/:id",
    name: "organization",
    component: OrganizationPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/organizations/create",
    name: "organizationCreate",
    component: OrganizationPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/users/:id",
    name: "user",
    component: UserPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/users/create",
    name: "userCreate",
    component: UserPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/estimation-params",
    name: "estimationParams",
    component: EstimationParamsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/estimation-params/create",
    name: "estimationParamCreate",
    component: EstimationParamPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/estimation-params/:id",
    name: "estimationParam",
    component: EstimationParamPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lk",
    name: "personalAccount",
    component: PersonalAccountPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/change-password",
    name: "changePassword",
    component: ChangePasswordPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: RecoveryPasswordPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/platforms",
    name: "platforms",
    component: PlatformsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/platforms/create",
    name: "platformCreate",
    component: PlatformPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dictionaries/platforms/:id",
    name: "platform",
    component: PlatformPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/analytics/trafic-plan",
    name: "traficPlanList",
    component: ListTraficPlansPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/analytics/trafic-plan/create",
    name: "traficPlanCreate",
    component: TraficPlanPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/analytics/trafic-plan/:id",
    name: "traficPlan",
    component: TraficPlanPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/analytics/estimation-settings",
    name: "estimationSettings",
    component: EstimationSettingsPage,
    meta: {
      requiresAuth: true,
    },
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == "login" && localStorage.getItem("access_token")) {
    next(from);
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !localStorage.getItem("access_token")
  ) {
    next({ name: "login", query: { next: to.fullPath?.toString() } });
  } else {
    next();
  }
});

export default router;
