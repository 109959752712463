<template>
  <Dropdown
    :modelValue="props.modelValue"
    :options="filteredItems"
    :optionLabel="optionName.label"
    :placeholder="isValueSetted ? 'Не выбрано' : 'Выберите значение'"
    :disabled="isDisabled"
    :filter="!isDisabled && !notSearch"
    @filter="onFilterItems"
    :option-value="useIdOnly ? optionName.pk : undefined"
    :invalid="isInvalid"
    @show="showItems"
    @before-show="beforeShowItems"
    :showToggleAll="!isDisabled"
    @update:modelValue="
      ($event) => {
        emit('update:modelValue', $event);
        catalogDataSettings.isLoadingFirst = false;
      }
    "
    showClear
    :loading="catalogDataSettings.isLoading"
    :emptyFilterMessage="
      catalogDataSettings.isLoading
        ? 'Поиск вариантов...'
        : 'Результатов не найдено'
    "
  >
    <template #value="slotProps">
      <div
        v-if="catalogDataSettings.isLoading"
        :class="
          props.modelValue && props.modelValue.length != 0 ? 'any-catalog' : ''
        "
      >
        &nbsp;
      </div>
      <div v-else-if="selectType == 'platform' && slotProps.value">
        <div
          class="chip-container"
          v-if="findItem(slotProps.value) != undefined"
        >
          <PlatformChip :icon-class="slotProps.value">
            {{ findItem(slotProps.value)["name"] }}</PlatformChip
          >
        </div>
      </div>
    </template>
    <template #option="slotProps">
      <div v-if="catalogDataSettings.isLoading">
        <div><Skeleton width="10rem" class="mb-2"></Skeleton></div>
      </div>
      <div v-else-if="selectType == 'platform'">
        <div class="chip-container">
          <PlatformChip :icon-class="slotProps.option[optionName.pk]">
            {{ slotProps.option[optionName.label] }}</PlatformChip
          >
        </div>
      </div>
      <div v-else>
        {{ slotProps.option[optionName.label] }}
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import Dropdown from "primevue/dropdown";
import Skeleton from "primevue/skeleton";

import { ref, watch, onMounted, computed } from "vue";
import { useCatalogData } from "./useCatalogData";
import PlatformChip from "@/shared/chips/PlatformChip.vue";

const props = defineProps([
  "modelValue",
  "isDisabled",
  "selectType",
  "useIdOnly",
  "isInvalid",
  "isValueSetted",
  "notSearch",
  "filterField",
  "filterValue",
  "withReload",
  "filterList",
]);
const emit = defineEmits(["update:modelValue"]);

const catalogDataSettings = ref({
  isLoading: false,
  filterStr: "",
  debounceTimeout: null as number | null,
  isLoadingFirst: true,
  optionName: "",
  optionValue: "",
  isLoadedAll: false,
});

const {
  store,
  items,
  optionName,
  addScrollHundler,
  updateSettings,
  onFilterItems,
  getSortingArray,
  sendItemRequest,
  replaceItems,
  objectToValue,
} = useCatalogData(
  props.selectType,
  catalogDataSettings.value,
  props.useIdOnly
);

let element: Element | null = null;

watch(
  () => catalogDataSettings.value.filterStr,
  () => {
    store.sortItems(getSortingArray([props.modelValue]));
    if (catalogDataSettings.value.debounceTimeout)
      clearTimeout(catalogDataSettings.value.debounceTimeout);
    catalogDataSettings.value.debounceTimeout = setTimeout(
      sendItemRequest,
      1000,
      [props.modelValue]
    );
  },
  { immediate: true }
);

watch(
  () => catalogDataSettings.value,
  (newValue) => {
    updateSettings(newValue);
  }
);

watch(
  () => props.modelValue,
  (newValue) => {
    if (catalogDataSettings.value.isLoadingFirst) {
      catalogDataSettings.value.isLoading = true;
      store.getItems("", [objectToValue(newValue)], true, true).then(() => {
        catalogDataSettings.value.isLoadingFirst = false;
        const items = replaceItems(newValue);
        if (items.length) emit("update:modelValue", items);
        setTimeout(() => {
          catalogDataSettings.value.isLoading = false;
        }, 200);
      });
    }
  }
);

onMounted(() => {
  if (props.withReload) {
    store.page = 0;
  }

  if (!items.value.length || props.withReload) {
    store.getItems("", getSortingArray([props.modelValue]), true, true);
  }
});

const filteredItems = computed(() => {
  if (props.filterField && props.filterValue) {
    return items.value.filter(
      (item: any) => item[props.filterField] == props.filterValue
    );
  }

  if (props.filterList && props.filterField) {
    return items.value.filter(
      (item: any) => !props.filterList.includes(item[props.filterField])
    );
  }
  return items.value;
});

const beforeShowItems = () => {
  store.sortItems(getSortingArray([props.modelValue]));
};

const showItems = () => {
  element = document.querySelectorAll(
    ".p-dropdown-panel .p-dropdown-items-wrapper"
  )[0];
  if (element) addScrollHundler(element);
};

const findItem = (pk: string) => {
  return items.value.filter((e: any) => e[optionName.value.pk] == pk)[0];
};
</script>

<style lang="scss">
.any-catalog {
  padding: 3.5px 0;
}

.p-dropdown-items-wrapper {
  padding-left: 4px;
  padding-top: 5px;
}

.chip-container {
  background-color: #eaeaf0;
  border-radius: 4px;
  width: fit-content;
}

.platform-chip {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .text {
    color: $main-font-color !important;
  }
}
</style>
