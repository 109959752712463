<template>
  <Dialog
    v-model:visible="visibleLocal"
    @hide="$emit('close')"
    modal
    header="Имя кампании существует в системе"
    :style="{ width: '30rem' }"
  >
    <span
      >Такая рекламная кампания уже существует, вы не можете создать ее
      повторно. Вы можете объединить рекламные кампании или отредактировать и
      переименовать актуальную рекламную кампанию.</span
    >
    <div class="flex buttons">
      <Button
        type="button"
        label="Объединить РК"
        @click="goToCampaign()"
      ></Button>
      <Button
        type="button"
        label="Переименовать"
        severity="secondary"
        @click="$emit('close')"
      ></Button>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps<{
  visible: boolean;
  id: number;
}>();

const emits = defineEmits(["close"]);

const visibleLocal = ref(props.visible);
watch(
  () => props.visible,
  (newValue) => {
    visibleLocal.value = newValue;
  }
);

const goToCampaign = () => {
  emits("close");
  router.push({
    name: "traficPlan",
    params: { id: props.id },
    query: { union: "t" },
  });
};
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 20px;
  button {
    flex: 1;

    &:first-child {
      margin-right: 20px;
    }
  }
}
</style>
