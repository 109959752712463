<template>
  <div class="form-filter">
    <div class="form-block">
      <div class="flex some-inputs">
        <div class="flex flex-col m-r-8 input-container">
          <label class="small-label">Slug</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputText
              id="slug"
              placeholder="Введите значение"
              v-model="param.slug"
              :invalid="isInvalidForm && param.slug == ''"
            />
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Имя параметра</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputText
              id="name"
              placeholder="Введите значение"
              v-model="param.name"
              :invalid="isInvalidForm && param.name == ''"
            />
          </SceletonInput>
        </div>
      </div>

      <div class="flex flex-col fl-1 m-b-8 m-t-8">
        <label for="name" class="small-label">Описание</label>
        <SceletonInput :is-loading="isLoadingData">
          <Textarea
            autoResize
            :invalid="isInvalidForm && param.description == ''"
            v-model="param.description"
            rows="5"
            cols="30"
          />
        </SceletonInput>
      </div>

      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-8 input-container">
          <label class="small-label">Значение по умолчанию</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputNumber
              placeholder="Введите значение"
              v-model="param.default_value"
              :maxFractionDigits="10"
              :invalid="isInvalidForm && param.default_value == null"
            />
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <div class="flex m-t-30 m-b-8 fl-jc-sb">
            <label for="updated" class="m-r-20"
              >Отображать в настройках эстимации</label
            >
            <InputSwitch v-model="param.active" inputId="updated" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useEstimationParamsStore } from "@/store/dictionaries/estimation_params";

import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";

import { areEstimationParamFieldsValid } from "@/services/dictionaries/estimation_params";
import InputSwitch from "primevue/inputswitch";
import SceletonInput from "@/shared/forms/SceletonInput.vue";

import { rootGetters } from "@/main";

const emit = defineEmits(["setLoading"]);
const route = useRoute();
const router = useRouter();
const store = useEstimationParamsStore();

const param = ref({
  name: "",
  slug: "",
  description: "",
  default_value: null as number | null,
  active: true,
});

const isInvalidForm = ref(false);
const isParamSetted = computed(() => {
  return Boolean(route.params.id);
});
const isLoadingData = ref(false);

const reload = () => {
  if (isParamSetted.value) {
    const paramId = parseInt(route.params.id);
    isLoadingData.value = true;
    store
      .getEstimationParam(paramId)
      .then((paramClone) => {
        if (paramClone) {
          param.value.name = paramClone.name;
          param.value.slug = paramClone.slug;
          param.value.description = paramClone.description;
          param.value.active = paramClone.active;
          param.value.default_value = paramClone.default_value;
        }
      })
      .finally(() => {
        isLoadingData.value = false;
      });
  }
};

onMounted(() => {
  reload();
});

const createEstimationParam = () => {
  if (!areEstimationParamFieldsValid(param.value)) {
    isInvalidForm.value = true;
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Проверьте, что все необходимые поля заполнены.`
    );
    return;
  }
  emit("setLoading", true);
  if (!isParamSetted.value) {
    performParamAction(() => store.createEstimationParam(param.value));
  } else {
    performParamAction(() =>
      store.updateEstimationParam(param.value, parseInt(route.params.id))
    );
  }
};

const performParamAction = (action: any) => {
  const handleSuccess = () => {
    router.push({ name: "estimationParams" });
    setTimeout(() => {
      store.activeFirst = false;
    }, 5000);
  };

  action()
    .then(handleSuccess)
    .catch((err: any) => {
      if (err.response && err.response.status == 409)
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
      if (err.response && err.response.status == 403)
        rootGetters.toast.show("warn", "Ошибка", "Нет доступа.");
    })
    .finally(() => {
      emit("setLoading", false);
    });
};

defineExpose({
  createEstimationParam,
});
</script>

<style lang="scss" scoped>
.form-filter {
  max-width: 100%;
  width: 100%;
  padding-bottom: 40px;
  min-width: 500px;
}

.form-block-title {
  margin-bottom: 8px;
}

.form-block:hover .group-button-container.hidden.dep {
  visibility: visible;
}

.role-container:hover .group-button-container.hidden.role {
  display: flex;
}

.small-label {
  color: #a19cab;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.43rem;
  padding-bottom: 4px;
  padding-left: 4px;
}

.group-button-container.dep.hidden {
  visibility: hidden;
}

.group-button-container.role.hidden {
  display: none;
}

.delete-button {
  border-radius: 5px;
  background: #f7f7fd;
  width: 32px;
  height: 32px;
  padding-left: 9px;
  cursor: pointer;

  &:hover {
    background-color: #fafaff;
  }
}

.add-button {
  background-color: #dbdaf9;
  border-radius: 5px;
  padding: 3px 9px;
  cursor: pointer;
  height: 32px;
  margin-left: 12px;

  &:hover {
    background-color: #d4d3f8;

    outline-color: transparent;
  }
}

.f-s-12 {
  font-size: 12px;
}

.sub-block {
  padding: 12px;
  background-color: #f7f7fd;
  border: 1px solid #d0d0d6;
  border-radius: 6px;
}

.some-inputs {
  max-width: 100%;
}

.input-container {
  max-width: calc(50% - 4px);
  width: calc(50% - 4px);
}
</style>
