<template>
  <ListItemsWithTableLayout>
    <template #body>
      <HandlerAccessDenied :is-denied="isDenied">
        <EstimationParamsFilterForm
          :isFilterEnabled="isFilterEnabled"
          @toggle-filter="isFilterEnabled = !isFilterEnabled"
          @set-filter="setFilter"
        ></EstimationParamsFilterForm>
        <TopListEstimationParamsPanel
          @set-common-filter="setCommonFilter"
          @toggle-filter="isFilterEnabled = !isFilterEnabled"
          :isFilterEnabled="isFilterEnabled"
          :isFilterSetted="isFilterSetted"
        ></TopListEstimationParamsPanel>
        <div
          id="table-container"
          class="table-container flex flex-col"
          :class="[
            filteredEstimationParams && filteredEstimationParams.length == 0
              ? 'empty'
              : 'filled',
          ]"
        >
          <EstimationsParamsTable
            :estimation-params="filteredEstimationParams"
            :isActiveFirst="activeFirstFromStore"
            :isLoading="isLoading"
            :rowsCount="rowsCount(43) * tableDisplaySettings.first"
            @sort-table="sortTable"
            @update-table="updateEstimationParamsList"
          ></EstimationsParamsTable>
          <EmptyTableMessage
            v-if="
              filteredEstimationParams &&
              filteredEstimationParams.length == 0 &&
              !isLoading
            "
          >
            <template #text>
              Примените другой фильтр или создайте первый первый параметр
              эстимации. <br />
              Для этого кликните на кнопку "Создать параметр эстимации".
            </template>
          </EmptyTableMessage>
        </div>
        <Paginator
          v-model:first="tableDisplaySettings.first"
          @update:first="updateEstimationParamsList"
          :rows="1"
          class="s-paginator"
          :totalRecords="store.estimationParamsPagesCount"
          template="PrevPageLink CurrentPageReport NextPageLink"
          currentPageReportTemplate="{first} из {totalRecords}"
        />
      </HandlerAccessDenied>
    </template>
  </ListItemsWithTableLayout>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";

import Paginator from "primevue/paginator";
import ListItemsWithTableLayout from "@/widgets/layouts/ListItemsWithTableLayout.vue";
import TopListEstimationParamsPanel from "@/entities/dictionaries/estimation_params/panels/TopListEstimationParamsPanel.vue";
import EmptyTableMessage from "@/entities/segments/EmptyTableMessage.vue";
import HandlerAccessDenied from "@/shared/base/HandlerAccessDenied.vue";
import EstimationsParamsTable from "@/entities/dictionaries/estimation_params/EstimationsParamsTable.vue";
import EstimationParamsFilterForm from "@/entities/dictionaries/estimation_params/forms/EstimationParamsFilterForm.vue";

import {
  useEstimationParamsStore,
  EstimationParamParams,
} from "@/store/dictionaries/estimation_params";
import { useAuthStore } from "@/store/auth/login";
import { rowsCount } from "@/services/utils/Pagination";
import { setEstimationParamParamsByFilter } from "@/services/dictionaries/estimation_params";
import { FilterForm } from "@/services/dictionaries/estimation_params";

const store = useEstimationParamsStore();
const authStore = useAuthStore();
const isLoading = ref(false);
const isFilterEnabled = ref(false);
const isFilterSetted = ref(false);
const tableDisplaySettings = ref({
  first: 0,
  orderField: "",
  commonFilter: "",
  filter: {} as FilterForm,
});

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(
    ["system:read", "system:edit"],
    ["dictionaries:read", "dictionaries:edit"],
    ["analytics:edit"]
  );
});

const filteredEstimationParams = computed(() => {
  return store.estimationParamsList;
});

const activeFirstFromStore = computed(() => store.activeFirst);

onMounted(() => {
  loadData();
});

const loadData = () => {
  isLoading.value = true;
  updateEstimationParamsList();
};

const setCommonFilter = (filter: string) => {
  tableDisplaySettings.value.commonFilter = filter;
  updateEstimationParamsList();
};

const sortTable = (order_by: string) => {
  tableDisplaySettings.value.orderField = order_by;
  updateEstimationParamsList();
};

const setFilter = (filter: FilterForm, isSetted: boolean) => {
  tableDisplaySettings.value.filter = filter;
  isFilterSetted.value = isSetted;
  updateEstimationParamsList();
};

const updateEstimationParamsList = () => {
  let isEnded = false;
  setTimeout(() => {
    if (!isEnded) {
      isLoading.value = true;
    }
  }, 200);
  const params: EstimationParamParams = {
    page: tableDisplaySettings.value.first + 1,
    per_page: rowsCount(43, 20),
  };

  setEstimationParamParamsByFilter(params, tableDisplaySettings.value);

  store.getEstimationParams(params).finally(() => {
    isLoading.value = false;
    isEnded = true;
  });
};
</script>
