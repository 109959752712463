<template>
  <div class="left-sidebar">
    <ul class="list-items" role="menu">
      <li v-for="route in filteredRoutes" :key="route.name" class="main-route">
        <RouterLink
          v-if="!('children' in route)"
          :to="{ name: route.name }"
          :class="[route.active ? 'active' : '']"
        >
          <span :class="['pi', route.icon]"></span>
          <div>{{ route.title }}</div>
        </RouterLink>
        <div class="parent" v-else>
          <div
            class="name"
            @click="route.expanded = !route.expanded"
            :class="[route.active ? 'active' : '']"
          >
            <span :class="['pi', route.icon]"></span>
            <div>{{ route.title }}</div>
          </div>

          <ul class="childs" :class="route.expanded ? 'visible' : 'hidden'">
            <li v-for="child in route.children" :key="child.name">
              <template v-if="child.name.length">
                <RouterLink
                  :to="{ name: child.name }"
                  :class="[child.active ? 'active' : '']"
                >
                  <div>{{ child.title }}</div>
                </RouterLink>
              </template>
              <template v-else>
                <div class="mid-parent">{{ child.title }}</div>
                <ul class="childs visible">
                  <li
                    v-for="childThird in child.children"
                    :key="childThird.name"
                  >
                    <RouterLink
                      :to="{ name: childThird.name }"
                      :class="[childThird.active ? 'active' : '']"
                    >
                      <div>{{ childThird.title }}</div>
                    </RouterLink>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/auth/login";

const route = useRoute();
const authStore = useAuthStore();
const filteredRoutes = ref<ParentRoute[]>([]);

onMounted(() => {
  filterRoutes();
  setActiveRoute(filteredRoutes.value);
});

const filterRoutes = () => {
  filteredRoutes.value = JSON.parse(JSON.stringify(routes.value));

  const payload = authStore.getPayload();
  if (payload && payload.scopes) {
    const permissions = payload.scopes
      .map(extractBeforeColon)
      .concat(payload.scopes);
    if (permissions.includes("system") || payload.user.is_superuser) return;
    filteredRoutes.value = filterRoutesByScopes(
      filteredRoutes.value,
      permissions
    );
  }
  return [];
};

function filterRoutesByScopes(
  routes: ParentRoute[],
  scopes: string[]
): ParentRoute[] {
  return routes
    .filter((route) => !route.scope || scopes.includes(route.scope))
    .map((route) => {
      if (route.children) {
        const filteredChildren = filterRoutesByScopes(route.children, scopes);
        return { ...route, children: filteredChildren };
      }
      return route;
    });
}

const setActiveRoute = (routes: any) => {
  const markActiveRoutes = (routeList: ParentRoute[]) => {
    routeList.forEach((routeInSidebar: ParentRoute) => {
      const active = route.fullPath.includes(routeInSidebar.path);
      routeInSidebar.active = active;

      if (routeInSidebar.children) {
        routeInSidebar.expanded = active;
        markActiveRoutes(routeInSidebar.children);
      }
    });
  };

  markActiveRoutes(routes);
};

const extractBeforeColon = (str: string): string => str.split(":")[0];

const routes = ref([
  {
    name: "listSegments",
    title: "Сегменты",
    icon: "pi-database",
    active: false,
    path: "/segments",
    scope: "segment",
  },
  {
    name: "listCampaigns",
    title: "Кампании",
    icon: "pi-briefcase",
    active: false,
    path: "/campaigns",
    scope: "campaign",
  },
  {
    name: "settings",
    title: "Настройки",
    icon: "pi-cog",
    active: false,
    path: "/settings",
    scope: "settings",
  },
  {
    name: "personalAccount",
    title: "Личный кабинет",
    icon: "pi-id-card",
    active: false,
    path: "/lk",
  },
  {
    name: "",
    title: "Справочники",
    icon: "pi-book",
    active: false,
    expanded: false,
    path: "/dictionaries",
    scope: "dictionaries",
    children: [
      {
        name: "listOrganizations",
        title: "Организации",
        active: false,
        path: "/organizations",
        scope: "organization",
      },
      {
        name: "users",
        title: "Пользователи",
        active: false,
        path: "/users",
        scope: "users",
      },
      {
        name: "adsCabinets",
        title: "Рекламные кабинеты",
        active: false,
        path: "/ads-cabinets",
      },
      {
        name: "platforms",
        title: "Рекламные площадки",
        active: false,
        path: "/platforms",
      },
      {
        name: "estimationParams",
        title: "Параметры эстимации",
        active: false,
        path: "/estimation-params",
        scope: "analytics:edit",
      },
    ],
  },
  {
    name: "",
    title: "Аналитика",
    icon: "pi-chart-line",
    active: false,
    expanded: false,
    path: "/analytics",
    scope: "analytics",
    children: [
      {
        name: "",
        title: "Кастомизация",
        active: false,
        path: "",
        scope: "analytics:edit",
        children: [
          {
            name: "traficPlanList",
            title: "План трафика",
            active: false,
            path: "/trafic-plan",
            scope: "analytics:edit",
          },
          {
            name: "estimationSettings",
            title: "Эстимация",
            active: false,
            path: "/estimation-settings",
            scope: "analytics:edit",
          },
        ],
      },
    ],
  },
]);

interface ParentRoute {
  name: string;
  title: string;
  icon?: string;
  path: string;
  active: boolean;
  expanded?: boolean;
  scope?: string;
  children?: ParentRoute[];
}
</script>

<style lang="scss" scoped>
.left-sidebar {
  position: fixed;
  top: 72px;
  bottom: 12px;
  left: 12px;

  width: 220px;

  background-color: white;
  border-radius: 8px;

  padding: 8px 10px;
  z-index: 1000;

  ul.list-items {
    padding: 0;
    margin: 0;

    li.main-route {
      list-style-type: none;

      span {
        padding-right: 8px;
      }

      > a,
      .parent .name {
        display: flex;
        align-items: center;
        height: 40px;

        color: $main-font-color;
        border-radius: 6px;
        font-size: 1rem;

        padding: 8px 16px;
        margin-bottom: 10px;

        &.active {
          background-color: #f1eff3;
        }

        &:hover {
          background-color: #f1eff3;
          color: $main-button-color;
        }
      }

      .parent .name {
        cursor: pointer;
      }
    }
  }

  ul.childs {
    margin-top: -2px;
    margin-left: 18px;
    padding: 0;
    display: none;

    li {
      list-style-type: none;
      margin-bottom: 8px;

      a {
        border-radius: 6px;
        display: block;
        padding-left: 8px;
        color: $main-font-color;
        line-height: 20px;
        padding-top: 4px;
        padding-bottom: 4px;

        &:hover {
          background-color: #eaeaf0;
        }

        &.active {
          background-color: #f1eff3;
        }
      }
    }

    &.visible {
      display: block;
    }
  }

  .mid-parent {
    padding-top: 4px;
    padding-left: 8px;
    margin-bottom: 18px;
  }
}
</style>
