<template>
  <TopListPanel
    :isFilterEnabled="isFilterEnabled"
    :isFilterSetted="isFilterSetted"
    :items="items"
    searchPlaceholder="Поиск по стратегии"
    buttonLabel="Добавить строку"
    routeCreate=""
    @set-common-filter="setCommonFilterParent"
    @toggle-filter="toggleFilterParent"
    :disabled-archive="true"
    :is-button-disabled="isDenied"
    @create-action="createAction"
  ></TopListPanel>

  <OverlayPanel ref="op" showCloseIcon class="estimation-overlay">
    <div class="estimation-panel">
      <h4>Добавление строки</h4>
      <div>
        <div class="flex flex-col fl-1 m-t-8">
          <label class="small-label"
            >Бренд <span class="red-text">*</span></label
          >
          <CatalogDataSingleInput
            selectType="brand"
            :use-id-only="true"
            :isSegmentSetted="false"
            :modelValue="setting.brand_pk"
            :is-invalid="isInvalidForm && setting.brand_pk == null"
            @update:modelValue="($event) => (setting.brand_pk = $event)"
          ></CatalogDataSingleInput>
        </div>
        <div class="flex flex-col m-t-8">
          <label for="strategy" class="small-label">Стратегия</label>
          <InputText
            id="strategy"
            placeholder="Введите значение"
            v-model="setting.strategy"
            aria-describedby="strategy-help"
          />
        </div>
        <Button
          label="Добавить"
          class="purple-button m-t-12 large-text"
          @click="addStroke"
        />
      </div>
    </div>
  </OverlayPanel>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useAuthStore } from "@/store/auth/login";
import { useEstimationsSettingsStore } from "@/store/analytics/estimation_settings";

import TopListPanel from "@/widgets/panels/TopListEntitiesPanel.vue";
import OverlayPanel from "primevue/overlaypanel";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import CatalogDataSingleInput from "@/widgets/forms/CatalogDataSingleInput.vue";

import { rootGetters } from "@/main";

const authStore = useAuthStore();
const store = useEstimationsSettingsStore();
const op = ref();
const isInvalidForm = ref(false);

const setting = ref({
  brand_pk: null as string | null,
  strategy: "",
});

defineProps<{
  isFilterEnabled?: boolean;
  isFilterSetted?: boolean;
}>();
const emit = defineEmits(["setCommonFilter", "toggleFilter", "updateTable"]);

const setCommonFilterParent = (value: string) => {
  emit("setCommonFilter", value);
};

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(["system:edit"], ["analytics:edit"]);
});

const toggleFilterParent = () => {
  emit("toggleFilter");
};

const createAction = (event: any) => {
  isInvalidForm.value = false;
  op.value.toggle(event);
};

const items = ref([
  { label: "Аналитика", archive: "both" },
  { label: "Настройка эстимации", archive: "both" },
]);

const addStroke = (event: any) => {
  if (setting.value.brand_pk == null) {
    isInvalidForm.value = true;
    return;
  }
  store
    .createEstimationSettings(setting.value)
    .then(() => {
      emit("updateTable");
      op.value.toggle(event);
    })
    .catch((err) => {
      if (err.response && err.response.status == 409)
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
    });
  // ;
};
</script>

<style lang="scss" scoped>
.estimation-panel {
  min-width: 400px;
  padding: 3px;

  h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 2px;
    margin-bottom: 12px;
  }
}

.small-label {
  color: #a19cab;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.43rem;
  padding-bottom: 2px;
  padding-left: 4px;
}

.large-text {
  font-size: 1rem;
  color: $main-font-color;
}
</style>
