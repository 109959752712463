<template>
  <DataTable
    :value="estimationParams"
    :class="[
      estimationParams && estimationParams.length == 0 ? 'empty-table' : '',
      isActiveFirst ? 'active-first' : '',
    ]"
    class="scroll-table-y main-table scrollbar-custom"
    stripedRows
    @sort="sortTable"
    removableSort
    scrollable
    :loading="isLoading"
    dataKey="id"
    columnResizeMode="expand"
  >
    <Column field="number" header="№" style="min-width: 44px">
      <template #body="slotProps">
        {{ rowsCount + slotProps.index + 1 }}
      </template>
    </Column>
    <Column field="name" header="Название параметра" sortable>
      <template #body="slotProps">
        <RouterLink
          :to="{ name: 'estimationParam', params: { id: slotProps.data.id } }"
        >
          {{ slotProps.data.name }}
        </RouterLink>
      </template>
    </Column>
    <Column field="slug" header="Slug" sortable> </Column>
    <Column field="cuser" header="Автор">
      <template #body="slotProps">
        {{ getUserFullName(slotProps.data.cuser) }}
      </template>
    </Column>
    <Column field="active" header="Отображать в настройках эстимации" sortable>
      <template #body="slotProps">
        <span v-if="slotProps.data.active">Да</span>
        <span v-else>Нет</span>
      </template>
    </Column>
    <Column
      field="ctime"
      header="Дата создания"
      sortable
      style="min-width: 153px"
    >
      <template #body="slotProps">
        {{ convertDateFromUTC(slotProps.data.ctime) }}
      </template>
    </Column>
    <Column
      field="mtime"
      header="Дата обновления"
      sortable
      style="min-width: 173px; max-width: 173px"
    >
      <template #body="slotProps">
        {{ convertDateFromUTC(slotProps.data.mtime) }}
      </template>
    </Column>
    <Column frozen alignFrozen="right">
      <template #body="slotProps">
        <div
          @click="(event) => toggle(slotProps.data.id, event)"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          class="button-menu"
        >
          <span :data-segment="1" class="pi pi-ellipsis-v"></span>
        </div>
      </template>
    </Column>
  </DataTable>
  <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
  <DeleteConfirmDialog
    deleted-name="параметр эстимации"
    :visible="confirmVisibleDelete"
    :blocked="blockedButtonDelete"
    @close="confirmVisibleDelete = false"
    @delete="deleteEstimationParam"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useEstimationParamsStore } from "@/store/dictionaries/estimation_params";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Menu from "primevue/menu";
import DeleteConfirmDialog from "@/shared/dialogs/DeleteConfirmDialog.vue";

import { convertDateFromUTC } from "@/services/utils/DateParser";
import { getUserFullName } from "@/services/utils/Names";
import { rootGetters } from "@/main";

defineProps<{
  estimationParams: any[];
  isActiveFirst: boolean;
  isLoading: boolean;
  rowsCount: number;
}>();
const emits = defineEmits(["sortTable", "updateTable"]);
const router = useRouter();
const store = useEstimationParamsStore();

let modifiedId = ref(0);
const confirmVisibleDelete = ref(false);
const blockedButtonDelete = ref(false);
const menu = ref();
const items = ref([
  {
    label: "Редактировать",
    icon: "pi pi-pencil",
    archive: "no_archive",
    command: () => {
      router.push({ name: "adsCabinet", params: { id: modifiedId.value } });
    },
  },
  {
    label: "Удалить",
    icon: "pi pi-trash",
    archive: "both",
    command: () => {
      confirmVisibleDelete.value = true;
    },
  },
]);

const toggle = (elemId: number, event: any) => {
  modifiedId.value = elemId;
  menu.value.toggle(event);
};

const sortTable = (event: any) => {
  let orderField = "";
  if (event.sortField)
    orderField = event.sortOrder > 0 ? event.sortField : `-${event.sortField}`;
  emits("sortTable", orderField);
};

const deleteEstimationParam = () => {
  blockedButtonDelete.value = true;
  store
    .deleteEstimationParam(modifiedId.value)
    .finally(() => {
      emits("updateTable");
      blockedButtonDelete.value = false;
      confirmVisibleDelete.value = false;
    })
    .catch((err) => {
      if (err.response && err.response.status == 400)
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
    });
};
</script>
