<template>
  <ListFilterForm
    :is-filter-enabled="props.isFilterEnabled"
    :initial-filter="initialFilter"
    @toggle-filter="emits('toggleFilter')"
    @set-filter="setParentFilter"
  >
    <template #fields="slotProps">
      <div class="flex flex-col">
        <label for="date" class="small-label">Дата создания</label>
        <Calendar
          id="date"
          panelClass="not-extended"
          placeholder="Выберите значение"
          v-model="slotProps.filter.date"
          aria-describedby="date-help"
          showIcon
          iconDisplay="input"
          inputId="icondisplay"
        />
      </div>
      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Бренд</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          selectType="brand"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.brand"
          @update:modelValue="($event) => (slotProps.filter.brand = $event)"
        ></CatalogDataBaseInput>
      </div>
      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Магазин</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          selectType="shop"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.shop"
          @update:modelValue="($event) => (slotProps.filter.shop = $event)"
        ></CatalogDataBaseInput>
      </div>

      <div class="flex">
        <div class="flex flex-col m-t-8 m-r-12">
          <label for="period" class="small-label">Дата начала</label>
          <Calendar
            id="date"
            placeholder="Дата"
            v-model="slotProps.filter.period.start"
            aria-describedby="date-help"
            showIcon
            iconDisplay="input"
            inputId="icondisplay"
          />
        </div>
        <div class="flex flex-col m-t-8">
          <label for="period" class="small-label">Дата окончания</label>
          <Calendar
            id="date"
            placeholder="Дата"
            v-model="slotProps.filter.period.end"
            aria-describedby="date-help"
            showIcon
            iconDisplay="input"
            inputId="icondisplay"
          />
        </div>
      </div>

      <div class="flex flex-col m-t-8">
        <label for="segmentMame" class="small-label">Наименование РК</label>
        <InputText
          id="segmentMame"
          placeholder="Введите название"
          v-model="slotProps.filter.name"
          aria-describedby="username-help"
        />
      </div>

      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Статус РК</label>
        <MultiSelect
          v-model="slotProps.filter.state"
          optionValue="name"
          :options="campaignsDictionariesStore.statesAllowed"
          optionLabel="display_name"
          placeholder="Выберите значение"
        />
      </div>

      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Пользователь</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          selectType="user"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.user"
          @update:modelValue="($event) => (slotProps.filter.user = $event)"
        ></CatalogDataBaseInput>
      </div>

      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Источник кампании</label>
        <CatalogDataBaseInput
          :not-search="true"
          :useIdOnly="true"
          selectType="source"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.platform"
          @update:modelValue="($event) => (slotProps.filter.platform = $event)"
        ></CatalogDataBaseInput>
      </div>

      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label"
          >Рекламная площадка плана трафика</label
        >
        <CatalogDataBaseInput
          :not-search="true"
          :useIdOnly="true"
          selectType="platform"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.platformPlanTrafic"
          @update:modelValue="
            ($event) => (slotProps.filter.platformPlanTrafic = $event)
          "
        ></CatalogDataBaseInput>
      </div>

      <div class="flex m-t-18 m-b-8 fl-jc-sb">
        <label for="updated">У кампании есть план трафика</label>
        <InputSwitch v-model="slotProps.filter.has_plan" inputId="updated" />
      </div>
    </template>
  </ListFilterForm>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import MultiSelect from "primevue/multiselect";
import InputSwitch from "primevue/inputswitch";
import CatalogDataBaseInput from "@/widgets/forms/CatalogDataBaseInput.vue";
import ListFilterForm from "@/widgets/forms/ListFilterForm.vue";

import { useCampaignsDictionariesStore } from "@/store/dictionaries/campaigns";

const props = defineProps<{
  isFilterEnabled: boolean;
}>();
const emits = defineEmits(["toggleFilter", "setFilter"]);
const campaignsDictionariesStore = useCampaignsDictionariesStore();

const initialFilter = {
  name: "",
  date: null,
  period: {
    start: null,
    end: null,
  },
  state: [] as number[],
  type: [] as number[],
  user: [] as number[],
  platform: [] as string[],
  platformPlanTrafic: [] as string[],
  brand: [] as string[],
  shop: [] as number[],
  has_plan: false,
};

const setParentFilter = (filter: any, isSetted: boolean) => {
  emits("setFilter", filter, isSetted);
};
</script>
