import { EstimationSettingsParams } from "@/store/analytics/estimation_settings";


export function setEstimationSettingsParamsByFilter(params: EstimationSettingsParams, settings: TableSettings) {
    if (settings.orderField) {
        params.order_by = settings.orderField;
    } else {
        params.order_by = "-mtime";
    }

    if (settings.commonFilter) {
        params.strategy__ilike = settings.commonFilter;
    }

    if (Object.keys(settings.filter).length > 0) {
        if (settings.filter.strategy) {
            params.strategy__ilike = settings.filter.strategy;
        }

        if (settings.filter.user.length > 0) {
            params.cuser__id__in = settings.filter.user.join(",");
        }

        if (settings.filter.brand.length > 0) {
            params.brand_pk__in = settings.filter.brand.join(",");
        }
    }
}

interface TableSettings {
    first: number;
    orderField: string;
    commonFilter: string;
    filter: FilterForm;
}

export interface FilterForm {
    strategy: string;
    user: number[];
    brand: string[];
}