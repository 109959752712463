<template>
  <TopListPanel
    :isArchiveEnabled="isArchiveEnabled"
    :isFilterEnabled="isFilterEnabled"
    :isFilterSetted="isFilterSetted"
    :items="items"
    :is-button-disabled="isDenied"
    searchPlaceholder="Поиск по названию"
    buttonLabel="Создать план трафика"
    routeCreate="traficPlanCreate"
    @toggle-archive="toggleArchiveParent"
    @set-common-filter="setCommonFilterParent"
    @toggle-filter="toggleFilterParent"
  ></TopListPanel>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useAuthStore } from "@/store/auth/login";
import TopListPanel from "@/widgets/panels/TopListEntitiesPanel.vue";

defineProps<{
  isArchiveEnabled: boolean;
  isFilterEnabled: boolean;
  isFilterSetted: boolean;
}>();
const emit = defineEmits(["setCommonFilter", "toggleArchive", "toggleFilter"]);

const authStore = useAuthStore();

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(["campaign:edit", "system:edit"]);
});

const toggleArchiveParent = () => {
  emit("toggleArchive");
};

const setCommonFilterParent = (value: string) => {
  emit("setCommonFilter", value);
};

const toggleFilterParent = () => {
  emit("toggleFilter");
};

const items = ref([
  { label: "Кастомизация", archive: "both" },
  { label: "План трафика", archive: "both" },
  { label: "Архив", archive: "archive" },
]);
</script>
