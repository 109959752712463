import { defineStore } from "pinia";
import { rootGetters } from "@/main";

import { moveItemToFront } from "@/services/utils/Arrays"

const endpoints = {
    getEstimationParams: { method: "get", url: "/client_reports_params" },
    getEstimationParam: { method: "get", url: "/client_reports_params/{client_report_param_id}" },
    deleteEstimationParam: { method: "delete", url: "/client_reports_params/{client_report_param_id}" },
    createEstimationParam: { method: "post", url: "/client_reports_params" },
    updateEstimationParam: { method: "put", url: "/client_reports_params/{client_report_param_id}" },
};

export const useEstimationParamsStore = defineStore("estimation_params", {
    state: () => ({
        estimationParamsList: [] as EstimationParamFromServer[],
        estimationParamsCount: 0,
        estimationParamsPerPage: 1,
        activeFirst: false,
        addedEstimationParamId: 0,
    }),
    getters: {
        estimationParamsPagesCount: (state) => {
            return Math.ceil(state.estimationParamsCount / state.estimationParamsPerPage)
        }
    },
    actions: {
        async createEstimationParam(estimationParam: BaseEstimationParam) {
            await rootGetters.api
                .proceedRequest(endpoints.createEstimationParam, {}, {}, estimationParam)
                .then((response) => {
                    if (response) {
                        this.addedEstimationParamId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Создание",
                            `Параметр клиентского отчета "${response.data.name}" успешно сохранен в раздел "Список параметров эстимации".`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async getEstimationParam(estimationParamId: number) {
            return await rootGetters.api
                .proceedRequest(endpoints.getEstimationParam, { client_report_param_id: estimationParamId })
                .then((response) => {
                    return response.data;
                });
        },
        async getEstimationParams(params: EstimationParamParams = { page: 1, per_page: 20 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getEstimationParams, {}, params
            ).then((response) => {
                this.estimationParamsList = response.data;
                if (this.estimationParamsList) {
                    this.estimationParamsPerPage = parseInt(response.headers["x-per-page"]);
                    this.estimationParamsCount = parseInt(response.headers["x-total-count"]);

                    if (this.addedEstimationParamId > 0) {
                        moveItemToFront(this.estimationParamsList, this.addedEstimationParamId);
                        this.addedEstimationParamId = 0;
                    }
                }
            });
        },
        async updateEstimationParam(estimationParam: BaseEstimationParam, estimationParamId: number) {
            await rootGetters.api
                .proceedRequest(endpoints.updateEstimationParam, { client_report_param_id: estimationParamId }, {}, estimationParam)
                .then((response) => {
                    if (response) {
                        this.addedEstimationParamId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Обновление",
                            `Параметр клиентского отчета "${response.data.name}" успешно обновлен.`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async deleteEstimationParam(estimationParamId: number) {
            await rootGetters.api
                .proceedRequest(endpoints.deleteEstimationParam, {
                    client_report_param_id: estimationParamId,
                })
        },
    }
})

export interface BaseEstimationParam {
    name: string;
    slug: string;
    description: string;
    active: boolean;
    default_value: number | null;
}

export interface EstimationParamFromServer extends BaseEstimationParam {
    id: number;
}

export interface EstimationParamParams {
    page: number;
    per_page: number;
    name?: string;
    name__ilike?: string;

    slug__ilike?: string;
    cdate?: string;
    mdate?: string;

    active?: boolean;
    cuser__id__in?: string;

    order_by?: string;
}

