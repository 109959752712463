<template>
  <TopPanel>
    <BreadCrumbs :items="filteredItems" />
    <SearchInput
      :placeholder="searchPlaceholder"
      class="search-input"
      @update:modelValue="($event) => (searchField = $event)"
      :modelValue="searchField"
    />

    <div class="baged-filter" v-if="!disabledFilter">
      <Button
        icon="pi pi-filter-fill"
        id="filter-button"
        severity="secondary"
        aria-label="Bookmark"
        class="m-l-12 f-s-0 toggled"
        @click="emit('toggleFilter')"
        :class="[isFilterEnabled ? 'filter-enabled' : '']"
      >
      </Button>
      <Badge v-if="isFilterSetted" class="bage"></Badge>
    </div>

    <ArchiveButton
      v-if="!disabledArchive"
      class="m-l-12 f-s-0"
      @click="emit('toggleArchive')"
      :class="[isArchiveEnabled ? 'archive-enabled' : '']"
    />
    <Button
      :disabled="isButtonDisabled"
      :label="buttonLabel"
      class="entity-create-button m-l-16 m-r-16"
      @click="createEntity"
    />
  </TopPanel>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";

import { useRouter } from "vue-router";

import BreadCrumbs from "@/shared/menu/BreadCrumbs.vue";
import SearchInput from "@/shared/forms/SearchInput.vue";
import Button from "primevue/button";
import ArchiveButton from "@/shared/buttons/ArchiveButton.vue";
import TopPanel from "@/widgets/sidebars/TopPanel.vue";
import Badge from "primevue/badge";

const props = defineProps<{
  isArchiveEnabled?: boolean;
  isFilterEnabled?: boolean;
  isFilterSetted?: boolean;
  searchPlaceholder: string;
  buttonLabel: string;
  routeCreate: string;
  items: BreadCrumbItem[];
  disabledArchive?: boolean;
  disabledFilter?: boolean;
  isButtonDisabled?: boolean;
}>();
const emit = defineEmits([
  "setCommonFilter",
  "toggleArchive",
  "toggleFilter",
  "createAction",
]);

const searchField = ref(null);
const debounceTimeout = ref(0);

const sendItemRequest = () => {
  emit("setCommonFilter", searchField.value);
};

watch(
  () => searchField.value,
  () => {
    if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
    debounceTimeout.value = setTimeout(sendItemRequest, 400);
  },
  { immediate: true }
);

const router = useRouter();

const filteredItems = computed(() => {
  return props.items.filter((item) =>
    props.isArchiveEnabled
      ? ["both", "archive"].includes(item.archive)
      : ["both", "no_archive"].includes(item.archive)
  );
});

const createEntity = (event: any) => {
  if (props.routeCreate.length > 0)
    router.push({
      name: props.routeCreate,
    });
  else emit("createAction", event);
};

interface BreadCrumbItem {
  label: string;
  archive: string;
  route?: string;
}
</script>

<style lang="scss" scoped>
.entity-create-button {
  flex-basis: 292px;
  flex-shrink: 1;
}

.search-input {
  flex: 1;
  flex-basis: 250px;
  flex-shrink: 0;
}

.archive-enabled svg {
  fill: gray;
}

.filter-enabled {
  color: $main-link-color;
}

.baged-filter {
  position: relative;
}

.bage {
  position: absolute;
  right: -1px;
}
</style>
