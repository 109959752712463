import { createStoreConfig } from "./common";
import { EstimationParamFromServer } from "../dictionaries/estimation_params";

const endpoints = {
    getItems: { method: "get", url: "/client_reports_params/base?active=true" },
};


export const useEstimationParamsDictionaryStore = createStoreConfig<EstimationParamFromServer>(endpoints, "estimation_params_dictionarie", {
    order_by: "id",
    in: "id__in",
    pk: "id",
    ilike: "name__ilike",
    sortOptions: { primarySortField: "id" }
});
