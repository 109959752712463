<template>
  <div class="form-filter">
    <div class="form-block">
      <div class="flex flex-col fl-1 m-b-8">
        <label for="name" class="small-label"
          >Наименование рекламного кабинета</label
        >
        <SceletonInput :is-loading="isLoadingData">
          <InputText
            id="name"
            placeholder="Введите значение"
            v-model="adsCabinet.name"
            :invalid="isInvalidForm && adsCabinet.name == ''"
          />
        </SceletonInput>
      </div>

      <div class="flex flex-col fl-1 m-b-8">
        <label for="name" class="small-label">Площадка</label>
        <SceletonInput :is-loading="isLoadingData">
          <CatalogDataSingleInput
            selectType="source"
            :useIdOnly="true"
            :is-invalid="isInvalidForm && adsCabinet.source_slug == null"
            :isSegmentSetted="false"
            :modelValue="adsCabinet.source_slug"
            @update:modelValue="($event) => (adsCabinet.source_slug = $event)"
          ></CatalogDataSingleInput>
        </SceletonInput>
      </div>

      <div class="flex flex-col fl-1 m-b-8">
        <label for="name" class="small-label">Логин</label>
        <SceletonInput :is-loading="isLoadingData">
          <InputText
            id="name"
            placeholder="Введите значение"
            v-model="adsCabinet.login"
            :invalid="isInvalidForm && adsCabinet.login == ''"
          />
        </SceletonInput>
      </div>

      <div class="flex flex-col fl-1">
        <label for="name" class="small-label">Токен</label>
        <SceletonInput :is-loading="isLoadingData">
          <InputText
            @focus="handleFocus"
            id="name"
            placeholder="Введите значение"
            v-model="adsCabinet.token"
            :invalid="isInvalidForm && adsCabinet.token == ''"
          />
        </SceletonInput>
      </div>

      <div class="flex m-t-18 m-b-8">
        <label for="updated" class="m-r-20">Собирать статистику</label>
        <InputSwitch
          v-model="adsCabinet.is_statistics_required"
          inputId="updated"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAdsCabinetsStore } from "@/store/dictionaries/ads_cabinets";

import InputText from "primevue/inputtext";
import CatalogDataSingleInput from "@/widgets/forms/CatalogDataSingleInput.vue";
import { areAdsCabinetFieldsValid } from "@/services/dictionaries/ads_cabinets";
import InputSwitch from "primevue/inputswitch";
import SceletonInput from "@/shared/forms/SceletonInput.vue";

import { rootGetters } from "@/main";

const emit = defineEmits(["setLoading"]);
const route = useRoute();
const router = useRouter();
const store = useAdsCabinetsStore();

const adsCabinet = ref({
  name: "",
  source_slug: null as string | null,
  login: "",
  is_statistics_required: true,
  token: "",
});

const isInvalidForm = ref(false);
const isAdsCabinetSetted = computed(() => {
  return Boolean(route.params.id);
});
const isLoadingData = ref(false);

const reload = () => {
  if (isAdsCabinetSetted.value) {
    const adsCabinetId = parseInt(route.params.id);
    isLoadingData.value = true;
    store
      .getAdsCabinet(adsCabinetId)
      .then((adsCabinetClone) => {
        if (adsCabinetClone) {
          adsCabinet.value.name = adsCabinetClone.name;
          adsCabinet.value.source_slug = adsCabinetClone.source_slug;
          adsCabinet.value.login = adsCabinetClone.login;
          adsCabinet.value.is_statistics_required =
            adsCabinetClone.is_statistics_required;
          adsCabinet.value.token = "************";
        }
      })
      .finally(() => {
        isLoadingData.value = false;
      });
  }
};

onMounted(() => {
  reload();
});

const createAdsCabinet = () => {
  if (!areAdsCabinetFieldsValid(adsCabinet.value)) {
    isInvalidForm.value = true;
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Проверьте, что все необходимые поля заполнены.`
    );
    return;
  }
  emit("setLoading", true);
  if (!isAdsCabinetSetted.value) {
    performAdsCabinetAction(() => store.createAdsCabinet(adsCabinet.value));
  } else {
    const adsCabinetCopy = { ...adsCabinet.value };
    if (adsCabinet.value.token == "************") {
      delete (adsCabinetCopy as any).token;
    }
    performAdsCabinetAction(() =>
      store.updateAdsCabinet(adsCabinetCopy, parseInt(route.params.id))
    );
  }
};

const performAdsCabinetAction = (action: any) => {
  const handleSuccess = () => {
    router.push({ name: "adsCabinets" });
    setTimeout(() => {
      store.activeFirst = false;
    }, 5000);
  };

  action()
    .then(handleSuccess)
    .catch((err: any) => {
      if (err.response && err.response.status == 409)
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
      if (err.response && err.response.status == 403)
        rootGetters.toast.show("warn", "Ошибка", "Нет доступа.");
    })
    .finally(() => {
      emit("setLoading", false);
    });
};

const handleFocus = () => {
  if (adsCabinet.value.token == "************") {
    adsCabinet.value.token = "";
  }
};

defineExpose({
  createAdsCabinet,
});
</script>

<style lang="scss" scoped>
.form-filter {
  max-width: 100%;
  width: 100%;
  padding-bottom: 40px;
  min-width: 500px;
}

.form-block-title {
  margin-bottom: 8px;
}

.form-block:hover .group-button-container.hidden.dep {
  visibility: visible;
}

.role-container:hover .group-button-container.hidden.role {
  display: flex;
}

.small-label {
  color: #a19cab;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.43rem;
  padding-bottom: 4px;
  padding-left: 4px;
}

.group-button-container.dep.hidden {
  visibility: hidden;
}

.group-button-container.role.hidden {
  display: none;
}

.delete-button {
  border-radius: 5px;
  background: #f7f7fd;
  width: 32px;
  height: 32px;
  padding-left: 9px;
  cursor: pointer;

  &:hover {
    background-color: #fafaff;
  }
}

.add-button {
  background-color: #dbdaf9;
  border-radius: 5px;
  padding: 3px 9px;
  cursor: pointer;
  height: 32px;
  margin-left: 12px;

  &:hover {
    background-color: #d4d3f8;

    outline-color: transparent;
  }
}

.f-s-12 {
  font-size: 12px;
}

.sub-block {
  padding: 12px;
  background-color: #f7f7fd;
  border: 1px solid #d0d0d6;
  border-radius: 6px;
}
</style>
