import { BaseEstimationParam, EstimationParamParams } from "@/store/dictionaries/estimation_params";
import { formatDateToYYYYMMDD } from "../utils/DateParser";

export function areEstimationParamFieldsValid(estimationParam: BaseEstimationParam): boolean {
    return !(!estimationParam.name || !estimationParam.default_value || !estimationParam.description || !estimationParam.slug)
}

export function setEstimationParamParamsByFilter(params: EstimationParamParams, settings: TableSettings) {
    if (settings.orderField) {
        params.order_by = settings.orderField;
    } else {
        params.order_by = '-mtime';
    }

    if (settings.commonFilter) {
        params.name__ilike = settings.commonFilter;
    }

    if (Object.keys(settings.filter).length > 0) {
        if (settings.filter.name) {
            params.name__ilike = settings.filter.name;
        }

        if (settings.filter.slug) {
            params.slug__ilike = settings.filter.slug;
        }

        if (settings.filter.user.length > 0) {
            params.cuser__id__in = settings.filter.user.join(",");
        }

        if (settings.filter.cdate)
            params.cdate = formatDateToYYYYMMDD(settings.filter.cdate);

        if (settings.filter.mdate)
            params.mdate = formatDateToYYYYMMDD(settings.filter.mdate);

        if (settings.filter.active.length > 0) {
            params.active = settings.filter.active == "1" ? true : false;
        }

    }
}

interface TableSettings {
    first: number;
    orderField: string;
    commonFilter: string;
    filter: FilterForm;
}

export interface FilterForm {
    name: string;
    slug: string;
    cdate: Date | null;
    mdate: Date | null;
    user: number[];
    active: string;
}