import { defineStore } from "pinia";
import { rootGetters } from "@/main";
import { getMap } from "@/services/segments/dictionaries";

const endpoints = {
    getPermissions: { method: "get", url: "/permissions" },
    getRolesPermissions: { method: "get", url: "/role_permissions" },
    getEntities: { method: "get", url: "/entities" },
    setPermission: { method: "post", url: "/role_permissions" },
    deletePermission: { method: "delete", url: "/role_permissions/{role_permission_id}" },
};

export const usePermissionsStore = defineStore("permissions", {
    state: () => ({
        entitiesList: [] as Entity[],
        permissionsList: [] as Permission[],
        rolePermissionsList: [] as any[]
    }),
    getters: {
        permissionMap(state) {
            return getMap(state.permissionsList)
        },
        entitiesMap(state) {
            return getMap(state.entitiesList)
        },
        rolesPermissionsMap(state) {
            const map = new Map<string, RoleDescription>();

            state.rolePermissionsList.forEach(item => {
                const key = `${item.role.id}_${item.entity.name}`;
                const entity_id = this.entitiesMap.get(item.entity.name)?.id
                if (entity_id)
                    map.set(key, {
                        id: item.id,
                        name: item.permission.name,
                        role_id: item.role.id,
                        entity_id: entity_id
                    })
            });

            return map;
        }
    },
    actions: {
        async getEntities(params = { page: 1, per_page: 100 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getEntities, {}, params
            ).then((response) => {
                this.entitiesList = response.data;
            });
        },
        async getPermissions(params = { page: 1, per_page: 100 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getPermissions, {}, params
            ).then((response) => {
                this.permissionsList = response.data;
                this.permissionsList.push({ name: "empty", display_name: "Не указан", id: 0 })
            });
        },
        async getRolesPermissions() {
            await rootGetters.api.proceedRequest(endpoints.getRolesPermissions).then((response) => {
                this.rolePermissionsList = response.data;
            })
        },
        async createRolePermission(permission: PermissionForCreate) {
            await rootGetters.api
                .proceedRequest(endpoints.setPermission, {}, {}, permission)
                .then((response) => {
                    return response.data;
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        async deletePermission(permission_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.deletePermission, {
                    role_permission_id: permission_id,
                })
        },
    }
})

interface Permission {
    id: number;
    name: string;
    display_name: string;
}

interface Entity {
    id: number;
    name: string;
    display_name: string;
}

export interface RoleDescription {
    id: number;
    name: string;
    role_id: number;
    entity_id: number;
}

interface PermissionForCreate {
    role_id: number,
    permission_id: number,
    entity_id: number
}