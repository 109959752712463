<template>
  <TopListPanel
    :isFilterEnabled="isFilterEnabled"
    :isFilterSetted="isFilterSetted"
    :items="items"
    searchPlaceholder="Поиск по названию"
    buttonLabel="Создать параметр эстимации"
    routeCreate="estimationParamCreate"
    @set-common-filter="setCommonFilterParent"
    @toggle-filter="toggleFilterParent"
    :disabled-archive="true"
    :is-button-disabled="isDenied"
  ></TopListPanel>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useAuthStore } from "@/store/auth/login";

import TopListPanel from "@/widgets/panels/TopListEntitiesPanel.vue";
const authStore = useAuthStore();

defineProps<{
  isFilterEnabled?: boolean;
  isFilterSetted?: boolean;
}>();
const emit = defineEmits(["setCommonFilter", "toggleFilter"]);

const setCommonFilterParent = (value: string) => {
  emit("setCommonFilter", value);
};

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(
    ["system:edit"],
    ["dictionaries:edit"],
    ["analytics:edit"]
  );
});

const toggleFilterParent = () => {
  emit("toggleFilter");
};

const items = ref([
  { label: "Справочники", archive: "both" },
  { label: "Список параметров эстимации", archive: "both" },
]);
</script>
