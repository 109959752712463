<template>
  <InnerPageLayout>
    <HandlerAccessDenied :is-denied="isDenied">
      <TopTraficPlanPanel
        :is-button-disabled="isDeniedSave"
        :isLoading="isLoading"
        @create-trafic-plan="createTraficPlan"
      ></TopTraficPlanPanel>
      <div class="form-container">
        <PlanTraficCreateForm
          :isLoading="isLoading"
          @set-loading="setLoading"
          ref="createFormRef"
        ></PlanTraficCreateForm>
      </div>
    </HandlerAccessDenied>
  </InnerPageLayout>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useAuthStore } from "@/store/auth/login";

import InnerPageLayout from "@/widgets/layouts/InnerPageLayout.vue";
import TopTraficPlanPanel from "@/entities/analytics/trafic_plan/panels/TopTraficPlanPanel.vue";
import PlanTraficCreateForm from "@/entities/analytics/trafic_plan/forms/PlanTraficCreateForm.vue";
import HandlerAccessDenied from "@/shared/base/HandlerAccessDenied.vue";

const isLoading = ref(false);
const createFormRef = ref(null);
const authStore = useAuthStore();

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(
    ["system:edit"],
    ["analytics:edit"],
    ["campaign:read", "campaign:edit", "system:read"]
  );
});

const isDeniedSave = computed(() => {
  return authStore.isDeniedForPermissions(
    ["system:edit"],
    ["analytics:edit"],
    ["campaign:read", "campaign:edit", "system:read"]
  );
});

const createTraficPlan = () => {
  if (createFormRef.value) createFormRef.value.createTraficPlan();
};

const setLoading = (value: boolean) => {
  isLoading.value = value;
};
</script>

<style lang="scss" scoped>
.form-container {
  position: relative;
  top: 77px;
  width: calc(100% - 316px);
}
</style>
