import { defineStore } from "pinia";
import { rootGetters } from "@/main";

import { moveItemToFront } from "@/services/utils/Arrays"

const endpoints = {
    getPlatforms: { method: "get", url: "/sources" },
    getPlatform: { method: "get", url: "/sources/{source_slug}" },
    deletePlatform: { method: "delete", url: "/sources/{source_slug}" },
    createPlatform: { method: "post", url: "/sources" },
    updatePlatform: { method: "put", url: "/sources/{source_slug}" },
};

export const usePlatformsStore = defineStore("platforms", {
    state: () => ({
        platformsList: [] as BasePlatform[],
        platformsCount: 0,
        platformsPerPage: 1,
        activeFirst: false,
        addedPlatformId: 0,
    }),
    getters: {
        platformsPagesCount: (state) => {
            return Math.ceil(state.platformsCount / state.platformsPerPage)
        },
        platformsMap: (state) => {
            return state.platformsList.reduce((map, platform) => {
                map[platform.slug] = platform;
                return map;
            }, {} as { [key: string]: BasePlatform });
        }
    },
    actions: {
        async createPlatform(platform: BasePlatform) {
            await rootGetters.api
                .proceedRequest(endpoints.createPlatform, {}, {}, platform)
                .then((response) => {
                    if (response) {
                        this.addedPlatformId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Создание",
                            `Рекламная площадка "${response.data.name}" успешно сохранена в раздел "Список рекламных площадок".`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async getPlatform(platformId: number) {
            return await rootGetters.api
                .proceedRequest(endpoints.getPlatform, { source_slug: platformId })
                .then((response) => {
                    return response.data;
                });
        },
        async getPlatforms(params: PlatformsParams = { page: 1, per_page: 20 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getPlatforms, {}, params
            ).then((response) => {
                this.platformsList = response.data;
                if (this.platformsList) {
                    this.platformsPerPage = parseInt(response.headers["x-per-page"]);
                    this.platformsCount = parseInt(response.headers["x-total-count"]);

                    if (this.addedPlatformId > 0) {
                        moveItemToFront(this.platformsList, this.addedPlatformId);
                        this.addedPlatformId = 0;
                    }
                }
            });
        },
        async updatePlatform(platform: BasePlatform, platformId: string) {
            await rootGetters.api
                .proceedRequest(endpoints.updatePlatform, { source_slug: platformId }, {}, platform)
                .then((response) => {
                    if (response) {
                        this.addedPlatformId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Обновление",
                            `Рекламная площадка "${response.data.name}" успешно обновлена.`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async deletePlatform(platformId: string) {
            await rootGetters.api
                .proceedRequest(endpoints.deletePlatform, {
                    source_slug: platformId,
                })
        },
    }
})

export interface BasePlatform {
    slug: string,
    name: string,
    is_platform: boolean;
    patterns: string[];
}

export interface PlatformsParams {
    page: number;
    per_page: number;

    name?: string;
    name__ilike?: string;
    is_platform?: boolean;
    patterns_ilike?: string;
    cdate?: string;
    mdate?: string;
    cuser__id__in?: string;

    order_by?: string;
}

