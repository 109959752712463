<template>
  <TopPanel>
    <BreadCrumbs :items="items" class="s-bread" />
    <div class="button-container">
      <Button
        label="Сохранить план трафика"
        class="save-button m-l-16 m-r-16"
        @click="createTraficPlan"
        :disabled="isButtonDisabled"
        :loading="isLoading"
      />
    </div>
  </TopPanel>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

import BreadCrumbs from "@/shared/menu/BreadCrumbs.vue";
import Button from "primevue/button";
import TopPanel from "@/widgets/sidebars/TopPanel.vue";

defineProps<{
  isLoading: boolean;
  isButtonDisabled?: boolean;
}>();

const route = useRoute();

const emit = defineEmits(["createTraficPlan"]);

const nameEdit = computed(() => {
  return route.params.id ? "Редактирование" : "Создание";
});

const items = ref([
  { label: "Кастомизация" },
  { label: "План трафика", route: "traficPlanList" },
  { label: `${nameEdit.value} плана трафика` },
]);

const createTraficPlan = () => {
  emit("createTraficPlan");
};
</script>

<style lang="scss" scoped>
.s-bread {
  margin-left: 18px;
}

.button-container {
  flex: 1;

  display: flex;
  justify-content: end;

  margin: 0 20px;
}

.save-button {
  min-width: 270px;
}
</style>
