<template>
  <div class="form-filter">
    <div class="form-block">
      <div class="flex flex-col fl-1 m-b-8">
        <label for="name" class="small-label">Название сегмента</label>
        <SceletonInput :is-loading="isLoadingData">
          <InputText
            id="name"
            placeholder="Введите название"
            v-model="segment.name"
            :disabled="isSegmentSetted"
          />
        </SceletonInput>
      </div>

      <div class="flex flex-col fl-1 m-t-8 m-b-8 none">
        <label for="type" class="small-label">Тип сегмента</label>
        <Dropdown
          id="type"
          @change="onTypeChange"
          placeholder="Выберите тип сегмента"
          v-model="segment.type"
          :options="types"
          optionLabel="name"
          aria-describedby="type-help"
          :disabled="isSegmentSetted"
        />
      </div>

      <div class="info-block flex">
        <span class="pi pi-info-circle info"></span>
        <div class="text">
          Численность аудитории с учетом правил комбинирования сегмента и
          указанных выборок покажет вам количество уникальных юзеров, попавших в
          выборку.
        </div>
      </div>
    </div>

    <div class="flex">
      <div class="form-block fl-50 m-r-13">
        <div class="flex flex-col fl-1">
          <div class="flex fl-jc-sb m-b-8 fl-1">
            <div>
              <label>Точная дата</label>
              <span
                class="pi pi-info-circle icon-right"
                v-tooltip="{
                  value:
                    'При выборе этого типа сегмент будет собираться только в рамках указанных дат. Если выбрана дата окончания, то он расчитается один раз и далее обновляться не будет. Если дата окончания не выбрана, то сегмент будет рассчитан и обновлен от даты начала до текущей даты.',
                  class: 'white-tooltip',
                }"
              ></span>
            </div>

            <InputSwitch
              v-model="segment.conditionsGroups[0].isFixedDate"
              :disabled="isSegmentSetted"
              @change="
                segment.conditionsGroups[0].isFlexibleDate =
                  !segment.conditionsGroups[0].isFixedDate
              "
              inputId="updated"
            />
          </div>
          <div class="flex flex-col m-b-8">
            <label for="period" class="small-label">Период покупки от</label>
            <SceletonInput :is-loading="isLoadingData">
              <CalendarInput
                :modelValue="segment.conditionsGroups[0].period.start"
                @update:modelValue="
                  ($event) =>
                    (segment.conditionsGroups[0].period.start = $event)
                "
                :disabled="
                  isSegmentSetted || segment.conditionsGroups[0].isFlexibleDate
                "
              ></CalendarInput>
            </SceletonInput>
          </div>
          <div class="flex flex-col">
            <label for="period" class="small-label">Период покупки до</label>
            <SceletonInput :is-loading="isLoadingData">
              <CalendarInput
                :modelValue="segment.conditionsGroups[0].period.end"
                @update:modelValue="
                  ($event) => (segment.conditionsGroups[0].period.end = $event)
                "
                :disabled="
                  isSegmentSetted || segment.conditionsGroups[0].isFlexibleDate
                "
              ></CalendarInput>
            </SceletonInput>
          </div>
        </div>
      </div>
      <div class="form-block fl-50 m-l-13">
        <div class="flex flex-col">
          <div class="flex fl-jc-sb m-b-7">
            <div>
              <label class="fl-1"
                >Смещение от текущей даты в меньшую сторону</label
              >
              <span
                class="pi pi-info-circle icon-right"
                v-tooltip="{
                  value:
                    'Смещение `от` означает нижнюю границу периода выборки, `до` - верхнюю. Например, от минус трех месяцев до минус одного месяца означает, что если сегодня 1 мая, то данные будут собраны от 1 февраля до 1 апреля. Такой сегмент каждый раз будет обновляться со сдвигом даты относительно текущей. Если дата окончания не указана, то концом периода будет считаться текущая дата. ',
                  class: 'white-tooltip',
                }"
              ></span>
            </div>
            <InputSwitch
              v-model="segment.conditionsGroups[0].isFlexibleDate"
              :disabled="isSegmentSetted"
              inputId="updated"
              @change="
                segment.conditionsGroups[0].isFixedDate =
                  !segment.conditionsGroups[0].isFlexibleDate
              "
            />
          </div>
          <div class="flex flex-col fl-1 m-b-7">
            <label class="small-label">От</label>
            <SceletonInput :is-loading="isLoadingData">
              <InputInterval
                :disabled="
                  isSegmentSetted || segment.conditionsGroups[0].isFixedDate
                "
                :modelValue="segment.conditionsGroups[0].flexible_period.start"
                @update:modelValue="
                  ($event) =>
                    (segment.conditionsGroups[0].flexible_period.start = $event)
                "
              ></InputInterval>
            </SceletonInput>
          </div>
          <div class="flex flex-col fl-1">
            <label class="small-label">До</label>
            <SceletonInput :is-loading="isLoadingData">
              <InputInterval
                :disabled="
                  isSegmentSetted || segment.conditionsGroups[0].isFixedDate
                "
                :modelValue="segment.conditionsGroups[0].flexible_period.end"
                @update:modelValue="
                  ($event) =>
                    (segment.conditionsGroups[0].flexible_period.end = $event)
                "
              ></InputInterval>
            </SceletonInput>
          </div>
        </div>
      </div>
    </div>

    <div class="form-block">
      <div class="flex flex-col">
        <label for="state" class="small-label">Категория</label>
        <Skeleton height="2.2rem" class="mb-2" v-if="isLoadingData"></Skeleton>
        <CategoryTreeInput
          :class="[isLoadingData ? 'none' : '']"
          :modelValue="segment.conditionsGroups[0].category"
          ref="childCategoryTree"
          :isSegmentSetted="isSegmentSetted"
          @update:modelValue="
            ($event: any) => (segment.conditionsGroups[0].category = $event)
          "
        ></CategoryTreeInput>
      </div>
    </div>

    <div
      class="form-block"
      v-if="segment.type.value != 'probability_of_purchase'"
    >
      <div class="flex some-inputs">
        <div class="flex flex-col m-r-8 input-container">
          <label for="period" class="small-label">Бренд</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataBaseInput
              selectType="brand"
              :isSegmentSetted="isSegmentSetted"
              :modelValue="segment.conditionsGroups[0].brand"
              @update:modelValue="
                ($event) => (segment.conditionsGroups[0].brand = $event)
              "
            ></CatalogDataBaseInput>
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <label for="period" class="small-label">Магазин</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataBaseInput
              selectType="shop"
              :isSegmentSetted="isSegmentSetted"
              :modelValue="segment.conditionsGroups[0].shop"
              @update:modelValue="
                ($event) => (segment.conditionsGroups[0].shop = $event)
              "
            ></CatalogDataBaseInput>
          </SceletonInput>
        </div>
      </div>
    </div>
  </div>
  <ChangeSegmentPageDialog
    :visible="confirmVisible"
    :blocked="blockedRouterButton"
    @exit="exitLeaveDialog"
    @create-segment="createSegmentFromDialog"
  ></ChangeSegmentPageDialog>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import InputInterval from "./InputInterval.vue";
import ChangeSegmentPageDialog from "@/entities/segments/dialogs/ChangeSegmentPageDialog.vue";
import CatalogDataBaseInput from "@/widgets/forms/CatalogDataBaseInput.vue";
import CategoryTreeInput from "@/entities/segments/forms/CategoryTreeInput.vue";
import CalendarInput from "./CalendarInput.vue";
import SceletonInput from "@/shared/forms/SceletonInput.vue";
import Skeleton from "primevue/skeleton";

import { rootGetters } from "@/main";
import { onBeforeRouteLeave } from "vue-router";
import { SegmentFilter, ConditionsGroup } from "@/services/segments/filters";
import { hasModifiedConditions } from "@/services/segments/utils";
import { useSegmentsStore } from "@/store/segments/segments";
import { useCategoriesStore } from "@/store/catalog_data/categories";
import { getFilterWithCategories } from "@/services/utils/Objects";
import {
  isValidPeriod,
  isValidFlexiblePeriod,
} from "@/services/utils/DateValidator";

const emit = defineEmits([
  "setCreateButton",
  "setLoading",
  "setFilter",
  "updateIsReadyToCount",
  "updateAudienceCount",
]);
const props = defineProps<{
  isLoading: boolean;
  audienceCount: number;
}>();

const router = useRouter();
const route = useRoute();
const segmentStore = useSegmentsStore();
const categoryStore = useCategoriesStore();
const isLoadingData = ref(false);

const types = ref([
  { name: "Фильтр аудитории", value: "audience_filter" },
  { name: "Вероятность покупки", value: "probability_of_purchase" },
]);

const segment = ref({
  name: "",
  type: types.value[0],
  conditionsGroups: [
    {
      period: {
        start: null,
        end: null,
      },
      flexible_period: {
        start: {
          interval: 0,
          unit: "DAY",
        },
        end: {
          interval: 0,
          unit: "DAY",
        },
      },
      category: [] as any[],
      brand: [] as string[],
      shop: [] as string[],
      isFixedDate: true,
      isFlexibleDate: false,
    },
  ],
});

const filter = new SegmentFilter();
const ignoreWatch = ref(false);
const confirmVisible = ref(false);
const blockedRouterButton = ref(false);
const isButtonenabled = computed(() => {
  return (
    hasModifiedConditions(segment.value.conditionsGroups) &&
    segment.value.name.length > 0 &&
    !isSegmentSetted.value
  );
});
const isReadyToCount = computed(() => {
  return hasModifiedConditions(segment.value.conditionsGroups);
});
const isSegmentSetted = computed(() => {
  return Boolean(route.params.id);
});
const toRouter = ref();
const childCategoryTree = ref(null);

watch(
  () => segment.value,
  (newValue) => {
    if (!ignoreWatch.value) filter.setFilterBySegment(newValue);
    if (!ignoreWatch.value && !isSegmentSetted.value) {
      router.replace({
        name: "segmentCreate",
        query: { filter: filter.stringFilter, type: segment.value.type.value },
      });
    }
    sendFilterToParent();
  },
  { deep: true }
);

watch(
  isButtonenabled,
  () => {
    emit("setCreateButton", isButtonenabled);
  },
  { immediate: true }
);

onBeforeRouteLeave((to, from) => {
  if (isButtonenabled.value && !confirmVisible.value && !props.isLoading) {
    confirmLeaving();
    toRouter.value = to;
    return false;
  }
  return true;
});

onMounted(() => {
  // дублирование
  if (segmentStore.duplicatedSegmentId > 0) {
    setSegmentById(segmentStore.duplicatedSegmentId, true);
    segmentStore.duplicatedSegmentId = 0;
  }

  //сегмент по id для просмотра
  if (isSegmentSetted.value) {
    setSegmentById(parseInt(route.params.id), false);
  }

  // сегмент из url
  if (route.query.filter && route.query.filter.length > 2) {
    ignoreWatch.value = true;
    filter.setFilterByStr(route.query.filter?.toString());
    setFilterGroup(filter.getSegmentConditionsByFilter());
  }

  if (route.query.type) {
    segment.value.type = types.value.filter(
      (t) => t.value == route.query.type?.toString()
    )[0];
  } else if (route.name == "segmentCreate") {
    if (filter.stringFilter != "()")
      router.replace({
        name: "segmentCreate",
        query: { filter: filter.stringFilter, type: segment.value.type.value },
      });
    else {
      router.replace({
        name: "segmentCreate",
        query: { type: segment.value.type.value },
      });
    }
  }

  Promise.resolve().then(() => {
    ignoreWatch.value = false;
  });
});

const setFilterGroup = (groups: ConditionsGroup[]) => {
  if (childCategoryTree.value)
    childCategoryTree.value.loadChoosenValues(groups[0].category);
  segment.value.conditionsGroups = groups;
};

const onTypeChange = () => {
  if (segment.value.type.value == "probability_of_purchase") {
    segment.value.conditionsGroups.forEach((group) => {
      group.brand = [];
      group.shop = [];
    });
  }
};

const setSegmentById = (cloneSegmentId: number, isDuplicate: boolean) => {
  isLoadingData.value = true;
  segmentStore
    .getSegment(cloneSegmentId)
    .then((cloneSegment) => {
      if (cloneSegment) {
        segment.value.name = isDuplicate
          ? cloneSegment.name + " (Копия)"
          : cloneSegment.name;
        segment.value.type = types.value.filter(
          (t) => t.value == cloneSegment.type
        )[0];

        if (cloneSegment.rich && !isDuplicate)
          emit("updateAudienceCount", cloneSegment.rich.rich);

        setFilterGroup(
          filter.getSegmentConditionsByFilter(
            cloneSegment.filter.filters_by_ids
          )
        );
      }
    })
    .finally(() => {
      isLoadingData.value = false;
    });
};

const createSegment = () => {
  if (
    (segment.value.conditionsGroups[0].isFixedDate &&
      !isValidPeriod(segment.value.conditionsGroups[0].period)) ||
    (segment.value.conditionsGroups[0].isFlexibleDate &&
      !isValidFlexiblePeriod(segment.value.conditionsGroups[0].flexible_period))
  )
    rootGetters.toast.show(
      "warn",
      "Ошибка",
      `Проверьте, что дата начала периода указана и она меньше, чем дата окончания.`
    );
  else if (props.audienceCount < 100000 || props.audienceCount > 6000000) {
    rootGetters.toast.show(
      "warn",
      "Ошибка",
      `Пересчитайте количество аудитории и убедитесь, что в сегменте не менее 100 тысяч пользователей и не более 6 миллионов.`
    );
  } else {
    const segmentToCreation = {
      name: segment.value.name,
      type: segment.value.type.value,
      filter: getFilterWithCategories(
        filter.filter,
        categoryStore.categoriesMap
      ),
      rich: props.audienceCount,
    };

    emit("setLoading", true);

    segmentStore
      .createSegment(segmentToCreation)
      .then(() => {
        router.push({ name: "listSegments" });
        setTimeout(() => {
          segmentStore.activeFirst = false;
        }, 5000);
      })
      .catch((err) => {
        if (err.response && err.response.status == 403)
          rootGetters.toast.show("warn", "Ошибка", "Нет доступа.");
      })
      .finally(() => {
        emit("setLoading", false);
      });
  }
};

const createSegmentFromDialog = () => {
  blockedRouterButton.value = true;
  createSegment();
};

const confirmLeaving = () => {
  blockedRouterButton.value = false;
  confirmVisible.value = true;
};

const exitLeaveDialog = () => {
  router.push(toRouter.value);
};

const sendFilterToParent = () => {
  emit("setFilter", filter.filter);
};

watch(isReadyToCount, (newValue) => {
  emit("updateIsReadyToCount", newValue);
});

defineExpose({
  createSegment,
});
</script>

<style lang="scss" scoped>
.form-filter {
  flex: 1;
  max-width: calc(100% - 240px - 30px - 36px);
  min-width: 780px;
}

.some-inputs {
  max-width: 100%;
}

.input-container {
  max-width: calc(50% - 4px);
  width: calc(50% - 4px);
}

.small-label {
  color: #a19cab;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.43rem;
  padding-bottom: 4px;
  padding-left: 4px;
}

.info-block {
  .info {
    font-size: 0.85rem;
    padding-top: 1px;
  }

  .text {
    font-size: 0.85rem;
    line-height: 1rem;
    padding-left: 8px;
  }

  padding-bottom: 14px;
}

:deep(.p-inputtext:disabled),
:deep(.p-dropdown.p-disabled),
:deep(.p-multiselect.p-disabled) {
  background-color: $page-background-color;
}

.icon-right {
  position: relative;
  top: 2px;
  left: 5px;
}
</style>
