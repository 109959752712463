<template>
  <ListFilterForm
    :is-filter-enabled="props.isFilterEnabled"
    :initial-filter="initialFilter"
    @toggle-filter="emits('toggleFilter')"
    @set-filter="setParentFilter"
  >
    <template #fields="slotProps">
      <div class="flex flex-col">
        <label for="state" class="small-label">Бренд</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          selectType="brand"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.brand"
          @update:modelValue="($event) => (slotProps.filter.brand = $event)"
        ></CatalogDataBaseInput>
      </div>

      <div class="flex flex-col m-t-8">
        <label for="strategy" class="small-label">Стратегия</label>
        <InputText
          id="strategy"
          placeholder="Введите название"
          v-model="slotProps.filter.strategy"
          aria-describedby="username-help"
        />
      </div>

      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Пользователь</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          selectType="user"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.user"
          @update:modelValue="($event) => (slotProps.filter.user = $event)"
        ></CatalogDataBaseInput>
      </div>
    </template>
  </ListFilterForm>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import CatalogDataBaseInput from "@/widgets/forms/CatalogDataBaseInput.vue";
import ListFilterForm from "@/widgets/forms/ListFilterForm.vue";

const props = defineProps<{
  isFilterEnabled: boolean;
}>();
const emits = defineEmits(["toggleFilter", "setFilter"]);

const initialFilter = {
  strategy: "",
  user: [] as number[],
  brand: [] as string[],
};

const setParentFilter = (filter: any, isSetted: boolean) => {
  emits("setFilter", filter, isSetted);
};
</script>
