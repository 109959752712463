import { defineStore } from "pinia";
import { rootGetters } from "@/main";

import { moveItemToFront } from "@/services/utils/Arrays"

const endpoints = {
    getOrganizations: { method: "get", url: "/organizations" },
    getOrganization: { method: "get", url: "/organizations/{organization_id}" },
    getBaseOrganization: { method: "get", url: "/organizations/base" },
    deleteOrganization: { method: "delete", url: "/organizations/{organization_id}" },
    createOrganization: { method: "post", url: "/organizations" },
    updateOrganization: { method: "put", url: "/organizations/{organization_id}" },
};

export const useOrganizationsStore = defineStore("organizations", {
    state: () => ({
        organizationsList: [] as Organization[],
        organizationsCount: 0,
        organizationsPerPage: 1,
        activeFirst: false,
        addedOrganizationId: 0,
    }),
    getters: {
        organizationsPagesCount: (state) => {
            return Math.ceil(state.organizationsCount / state.organizationsPerPage)
        }
    },
    actions: {
        async createOrganization(organization: OrganizationForCreate) {
            await rootGetters.api
                .proceedRequest(endpoints.createOrganization, {}, {}, organization)
                .then((response) => {
                    if (response) {
                        this.addedOrganizationId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Создание",
                            `Организация "${response.data.name}" успешно сохранена в раздел "Список организаций".`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async getBaseOrganizationByTenant(tenant: string) {
            const params: OrganizationParams = { page: 1, per_page: 20, tenant: tenant }
            return await rootGetters.api
                .proceedRequest(endpoints.getBaseOrganization, {}, params)
                .then((response) => {
                    return response.data;
                });
        },
        async getOrganization(organizationId: number) {
            return await rootGetters.api
                .proceedRequest(endpoints.getOrganization, { organization_id: organizationId })
                .then((response) => {
                    return response.data;
                });
        },
        async getOrganizations(params: OrganizationParams = { page: 1, per_page: 20 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getOrganizations, {}, params
            ).then((response) => {
                this.organizationsList = response.data;
                if (this.organizationsList) {
                    this.organizationsPerPage = parseInt(response.headers["x-per-page"]);
                    this.organizationsCount = parseInt(response.headers["x-total-count"]);

                    if (this.addedOrganizationId > 0) {
                        //console.log(this.addedOrganizationId)
                        moveItemToFront(this.organizationsList, this.addedOrganizationId);
                        this.addedOrganizationId = 0;
                    }
                }
            });
        },
        async updateOrganization(organization: OrganizationForCreate, organizationId: number) {
            await rootGetters.api
                .proceedRequest(endpoints.updateOrganization, { organization_id: organizationId }, {}, organization)
                .then((response) => {
                    if (response) {
                        this.addedOrganizationId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Обновление",
                            `Организация "${response.data.name}" успешно обновлена.`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async deleteOrganization(organization_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.deleteOrganization, {
                    organization_id: organization_id,
                })
        },
        async getOrganizationBase(organizationId: number) {
            const params: OrganizationParams = { page: 1, per_page: 20, id: organizationId }
            return await rootGetters.api.proceedRequest(
                endpoints.getOrganizations, {}, params
            ).then((response) => {
                if (response.data && response.data.length > 0) {
                    return response.data[0]
                }
            });
        },
    }
})


interface Role {
    id?: number;
    name: string;
    description: string;
    ctime?: string;
    mtime?: string;
}

export interface Department {
    id?: number;
    name: string;
    description: string;
    roles?: Role[];
}

interface OrganizationDetails {
    name: string;
    description: string;
    tenant: string;
}

interface OrganizationForCreate {
    departments?: Department[];
    organization: OrganizationDetails;
}

export interface Organization {
    name: string;
    description: string;
    tenant: string;
    departments: Department[];
}

export interface OrganizationParams {
    page: number;
    per_page: number;
    name?: string;
    name__ilike?: string;
    id?: number;
    tenant?: string;

    order_by?: string;
}

export interface OrganizationFromServer {
    id: number;
    name: string;
    description: string;
    tenant: string;
    departments: DepartmentFromServer[];
}

export interface DepartmentFromServer {
    id: number;
    name: string;
    description: string;
    roles: RoleFromServer[];
}

interface RoleFromServer {
    id: number;
    name: string;
    description: string;
    ctime?: string;
    mtime?: string;
}