import { defineStore } from 'pinia'
import { rootGetters } from '@/main'
import { getSubstringBetweenDots, decodeBase64 } from '@/services/utils/Strings'
import { getIntersection } from '@/services/utils/Arrays';
import { useOrganizationsStore } from '../dictionaries/organizations';

const get_user_info_url = "/users/me"
const login_url = "/auth/login"
const refresh_token = "/auth/refresh"
const change_tenant = "/auth/change-tenant"
const reset_password = "/auth/password-change/request/"
const confirm_reset_password = "/auth/password-change/confirm"


export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: null as UserInfo | null,
        isPasswordRecovered: false
    }),
    getters: {
        isLoggedIn: () => localStorage.getItem('access_token'),
    },
    actions: {
        setPasswordRecovered(value: boolean) {
            this.isPasswordRecovered = value;
        },
        async getUserInfo() {
            await rootGetters.api.client
                .get(get_user_info_url)
                .then((response) => {
                    this.user = response.data
                })
                .catch((err) => {
                    if (err.response && err.response.status == 403)
                        throw err;

                    rootGetters.errorHandler.showError(err);
                })
        },
        async login(crendetails: LoginInfo) {
            await rootGetters.api.client.post(login_url, crendetails,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json'
                    }
                }
            )
                .then((response) => {
                    this.setTokens(response.data).then(() => {
                        this.getUserInfo()
                    })
                })
                .catch((err) => {
                    if (err.response && err.response.status == 403)
                        throw err;

                    rootGetters.errorHandler.showError(err);
                })
        },
        async logout() {
            this.removeTokens();
        },

        async refresh_token() {
            localStorage.removeItem("access_token");
            await rootGetters.api.client
                .post(refresh_token, {},
                    {
                        headers: {
                            'x-refresh-token': localStorage.getItem("refresh_token"),
                            'Accept': 'application/json'
                        }
                    }
                )
                .then((response) => {
                    this.setTokensFromArray(response.data).then(() => {
                        this.getUserInfo()
                    })
                })
                .catch((err) => {
                    console.log(err)
                    console.log(localStorage.getItem("refresh_token"))
                    console.log("ТОКЕН ПРОСРАЛСЯ")
                    this.removeTokens()
                    return Promise.reject(err)
                })
        },
        setTokens(response: TokenResponse) {
            return this.setTokensFromArray([response.access_token, response.refresh_token])
        },
        setTokensFromArray(tokens: string[]) {
            return new Promise((resolve) => {
                localStorage.setItem('access_token', tokens[0])
                this.setPayload(tokens[0])
                localStorage.setItem('refresh_token', tokens[1])
                resolve("");
            })
        },
        setPayload(token: string) {
            const info = getSubstringBetweenDots(token)
            if (info) {
                const userPayload = decodeBase64(info)
                if (userPayload) {
                    localStorage.setItem('payload', userPayload)
                }
            }
        },
        removeTokens() {
            return new Promise((resolve) => {
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                localStorage.removeItem("payload");
                resolve("");
            })
        },
        getPayload(): Payload | null {
            const payload = localStorage.getItem("payload");
            if (payload) {
                return JSON.parse(payload) as Payload;
            }
            return null;
        },
        async getOrganizationByUserTenant() {
            const payload = this.getPayload();
            if (payload && payload.tenant) {
                const store = useOrganizationsStore();
                const org = await store.getBaseOrganizationByTenant(payload.tenant);
                if (org.length > 0) return org[0];
            }
            return null;
        },
        async changeTenant(tenant: string) {
            localStorage.removeItem("access_token");
            await rootGetters.api.client
                .post(change_tenant, {},
                    {
                        headers: {
                            'x-refresh-token': localStorage.getItem("refresh_token"),
                            'Accept': 'application/json'
                        },
                        params: {
                            'tenant': tenant
                        }
                    }
                )
                .then((response) => {
                    this.setTokensFromArray(response.data).then(() => {
                        this.getUserInfo()
                    })
                    location.reload();
                })
                .catch((err) => {
                    console.log(err)
                    console.log(localStorage.getItem("refresh_token"))
                    console.log("ТОКЕН ПРОСРАЛСЯ")
                    this.removeTokens()
                    return Promise.reject(err)
                })
        },
        isDeniedForPermissions(permissions: string[], ...optionalPermissionsGroups: string[][]) {
            const payload = this.getPayload()
            if (payload && payload.user && payload.user.is_superuser) return false;
            if (payload && payload.scopes) {
                const intersection = getIntersection(payload.scopes, permissions)
                if (intersection.length > 0) return false;
                if (intersection.length == 0 && optionalPermissionsGroups.length == 0) return true;

                for (const optionalPermissions of optionalPermissionsGroups) {
                    const optionalIntersection = getIntersection(payload.scopes, optionalPermissions);
                    if (optionalIntersection.length === 0) {
                        return true;
                    }
                }
                // Если обязательные разрешения найдены или для всех групп дополнительных разрешений совпадения найдены, доступ разрешен
                return false;
            }
            return true;
        },
        async resetPassword(email: string) {
            await rootGetters.api.client
                .post(reset_password, { email: email })
                .catch((err) => {
                    return Promise.reject(err)
                })
        },
        async resetPasswordConfirm(nonce: string, password: string) {
            await rootGetters.api.client
                .post(confirm_reset_password, { nonce: nonce, password: password })
                .catch((err) => {
                    return Promise.reject(err)
                })
        }
    },
})

export interface UserInfo {
    id: number
    email: string
    fname: string
    lname: string
    role: Role | null
}

interface Role {
    name: string;
    department: Department;
}

interface Department {
    name: string;
    organization: Organization;
}

interface Organization {
    name: string;
}

interface LoginInfo {
    username: string
    password: string
}

interface TokenResponse {
    access_token: string;
    refresh_token: string;
}

export interface Payload {
    user: UserPayload;
    token_type: string;
    scopes: string[];
    exp: number;
    tenant: string;
}

export interface UserPayload {
    id: number;
    fname: string;
    lname: string;
    email: string;
    is_superuser: boolean;
}