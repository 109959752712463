<template>
  <div class="form-filter">
    <div class="form-block">
      <div class="form-block-title">
        Создание наименования рекламной кампании
      </div>

      <div class="flex some-inputs">
        <div class="flex flex-col m-r-8 input-container">
          <label class="small-label">Бренд</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataSingleInput
              selectType="brand"
              :is-invalid="isInvalidForm && campaign.brand == null"
              :isSegmentSetted="false"
              :modelValue="campaign.brand"
              @update:modelValue="($event) => (campaign.brand = $event)"
            ></CatalogDataSingleInput>
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Магазин</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataSingleInput
              selectType="shop"
              :is-invalid="isInvalidForm && campaign.shop == null"
              :isSegmentSetted="false"
              :modelValue="campaign.shop"
              @update:modelValue="($event) => (campaign.shop = $event)"
            ></CatalogDataSingleInput>
          </SceletonInput>
        </div>
      </div>

      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-8 input-container">
          <label class="small-label">Стратегия</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputText
              placeholder="Введите значение"
              v-model="campaign.strategy"
              :disabled="false"
            />
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Дополнительное поле</label>
          <SceletonInput :is-loading="isLoadingData">
            <InputText
              placeholder="Введите значение"
              v-model="campaign.extra_info"
              :disabled="false"
            />
          </SceletonInput>
        </div>
      </div>

      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-8 input-container">
          <label class="small-label"
            >Период запуска рекламной кампании от</label
          >
          <SceletonInput :is-loading="isLoadingData">
            <CalendarBaseInput
              :modelValue="campaign.date_from"
              :is-invalid="isInvalidForm && campaign.date_from == null"
              @update:modelValue="($event) => (campaign.date_from = $event)"
              :disabled="false"
            ></CalendarBaseInput>
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label"
            >Период запуска рекламной кампании до</label
          >
          <SceletonInput :is-loading="isLoadingData">
            <CalendarBaseInput
              :min-date="new Date()"
              :modelValue="campaign.date_to"
              :is-invalid="isInvalidForm && campaign.date_to == null"
              @update:modelValue="($event) => (campaign.date_to = $event)"
              :disabled="false"
            ></CalendarBaseInput>
          </SceletonInput>
        </div>
      </div>
    </div>

    <div class="form-block">
      <div class="flex flex-col fl-1">
        <label class="small-label">Название рекламной кампании</label>
        <SceletonInput :is-loading="isLoadingData">
          <IconField>
            <InputIcon
              class="pi"
              :class="
                isNameFilled
                  ? 'pi-check-circle violete'
                  : 'pi-exclamation-circle red'
              "
              v-tooltip="{
                value: getMessage(isNameFilled),
                class: 'white-tooltip',
              }"
            >
            </InputIcon>
            <InputText
              class="campaign-name"
              placeholder="Введите название"
              v-model="campaignName"
              :disabled="true"
            />
          </IconField>
        </SceletonInput>
      </div>
    </div>

    <div
      class="form-block"
      v-for="(segment, idx) in campaign.campaign_segments"
      :key="`segment-${idx}`"
      :class="[idx != campaign.campaign_segments.length - 1 ? 'hidden' : '']"
    >
      <div class="flex flex-end m-b-8">
        <div class="flex flex-col fl-1">
          <label class="small-label">Сегмент</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataSingleInput
              :with-reload="true"
              :is-invalid="isInvalidForm && segment.segment_id == null"
              selectType="segment"
              :isSegmentSetted="false"
              :modelValue="segment.segment"
              @update:modelValue="($event) => chooseSegment(segment, $event)"
            ></CatalogDataSingleInput>
          </SceletonInput>
        </div>
        <div class="flex fl-jc-sb f-s-0 m-l-20 m-b-7 m-l-20">
          <label
            class="p-r-20 pointer"
            for="updated"
            :class="[segment.updatable_locked ? 'locked' : '']"
            >Обновлять сегмент</label
          >
          <InputSwitch
            v-model="segment.is_segment_updatable"
            :disabled="segment.updatable_locked"
            inputId="updated"
          />
        </div>
      </div>

      <div class="flex some-inputs">
        <div class="flex flex-col m-r-8 input-container">
          <label class="small-label">Рекламная площадка</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataSingleInput
              :not-search="true"
              :use-id-only="true"
              selectType="platform"
              :is-invalid="isInvalidForm && segment.platform_slug == null"
              :isSegmentSetted="false"
              :modelValue="segment.platform_slug"
              @update:modelValue="($event) => (segment.platform_slug = $event)"
            ></CatalogDataSingleInput>
          </SceletonInput>
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Кабинет</label>
          <SceletonInput :is-loading="isLoadingData">
            <CatalogDataSingleInput
              :with-reload="true"
              :use-id-only="true"
              filter-field="source_slug"
              :filter-value="segment.platform_slug"
              selectType="ads_cabinet"
              :is-disabled="segment.platform_slug == null"
              :is-invalid="isInvalidForm && segment.ads_cabinet_id == null"
              :isSegmentSetted="false"
              :modelValue="segment.ads_cabinet_id"
              @update:modelValue="($event) => (segment.ads_cabinet_id = $event)"
            ></CatalogDataSingleInput>
          </SceletonInput>
        </div>
      </div>

      <div class="group-button-container flex">
        <div
          v-if="campaign.campaign_segments.length > 1"
          class="delete-button flex flex-center"
          @click="deleteSegment(idx)"
        >
          <span class="pi pi-trash"></span>
        </div>
        <div class="group-button flex flex-center" @click="addSegment(idx)">
          <span class="pi pi-plus"></span>
          <div class="p-l-10">Группа</div>
        </div>
      </div>
    </div>
    <DuplicatedCampaignDialog
      :id="duplicatedId"
      :visible="confirmVisible.duplicate"
      @close="confirmVisible.duplicate = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import InputText from "primevue/inputtext";
import CatalogDataSingleInput from "@/widgets/forms/CatalogDataSingleInput.vue";
import CalendarBaseInput from "@/shared/forms/CalendarBaseInput.vue";
import InputSwitch from "primevue/inputswitch";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import DuplicatedCampaignDialog from "@/shared/dialogs/DuplicatedCampaignDialog.vue";
import SceletonInput from "@/shared/forms/SceletonInput.vue";

import { Brand } from "@/store/catalog_data/brands";
import { Shop } from "@/store/catalog_data/shops";
import { useCampaignStore } from "@/store/campaigns/campaigns";
import {
  getCampaignName,
  isCampaignNameValidated,
  isSegmentsFilled,
  isNotValidPeriod,
  prepareModelToServer,
  convertServerToModel,
} from "@/services/campaigns/utils";
import { rootGetters } from "@/main";

const emit = defineEmits(["setCreateButton", "setLoading"]);
defineProps<{
  isLoading: boolean;
}>();

const route = useRoute();
const router = useRouter();
const store = useCampaignStore();
const isNameFilled = ref(false);
const isInvalidForm = ref(false);
const confirmVisible = ref({
  duplicate: false,
});

const duplicatedId = ref(0);
const useNameDefault = ref(false);
const nameDefault = ref(null);
const isLoadingData = ref(false);

const campaign = ref({
  brand: null as Brand | null,
  shop: null as Shop | null,
  name: "",
  strategy: "",
  extra_info: "",
  date_from: null as Date | null,
  date_to: null as Date | null,
  campaign_segments: [
    {
      segment_id: null as number | null,
      segment: null as object | null,
      ads_cabinet_id: null as number | null,
      platform_slug: null as string | null,
      is_segment_updatable: false,
      updatable_locked: false,
    },
  ],
});

const campaignWatchFields = computed(() => ({
  brand: campaign.value.brand,
  shop: campaign.value.shop,
  name: campaign.value.name,
  strategy: campaign.value.strategy,
  extra_info: campaign.value.extra_info,
  date_from: campaign.value.date_from,
  date_to: campaign.value.date_to,
}));

const isCampaignSetted = computed(() => {
  return Boolean(route.params.id);
});

const campaignName = computed(() => {
  return getCampaignName(campaign.value, useNameDefault.value);
});

const chooseSegment = (segment: any, event: any) => {
  if (event) {
    segment.segment_id = event.id;
    if (event.is_fixed_end_date) segment.updatable_locked = true;
  } else {
    segment.segment_id = null;
  }

  segment.segment = event;
};

const reload = () => {
  if (isCampaignSetted.value || store.duplicateCampaignId) {
    const campaignId = parseInt(route.params.id) || store.duplicateCampaignId;
    isLoadingData.value = true;
    store.getCampaign(campaignId).then((campaignClone) => {
      if (campaignClone) {
        campaign.value = convertServerToModel(campaignClone);
        useNameDefault.value = true;
        isLoadedFirst.value = true;
        nameDefault.value = JSON.parse(JSON.stringify(campaign.value));
      }
      if (store.duplicateCampaignId) {
        const extraValue = campaign.value.extra_info
          ? campaign.value.extra_info
          : "";
        campaign.value.extra_info = extraValue + "копия";
      }
      isLoadingData.value = false;
    });
  }
};

onMounted(() => {
  reload();
});

watch(
  () => campaignName.value,
  () => {
    isNameFilled.value = isCampaignNameValidated(campaign.value);
  }
);

watch(
  () => route.params.id,
  () => {
    reload();
  }
);

const isLoadedFirst = ref(true);

watch(campaignWatchFields, (newValue) => {
  if (nameDefault.value) {
    if (
      (!isLoadedFirst.value || store.duplicateCampaignId) &&
      useNameDefault.value &&
      (newValue.extra_info !== nameDefault.value.extra_info ||
        newValue.brand !== nameDefault.value.brand ||
        newValue.shop !== nameDefault.value.shop ||
        newValue.strategy !== nameDefault.value.strategy ||
        newValue.date_from !== nameDefault.value.date_from ||
        newValue.date_to !== nameDefault.value.date_to)
    ) {
      useNameDefault.value = false;
    }
  }
  if (useNameDefault.value != undefined) isLoadedFirst.value = false;
});

const addSegment = (idx: number) => {
  campaign.value.campaign_segments.splice(idx + 1, 0, {
    segment_id: null,
    ads_cabinet_id: null,
    segment: null,
    platform_slug: null,
    is_segment_updatable: false,
    updatable_locked: false,
  });
};

const deleteSegment = (idx: number) => {
  campaign.value.campaign_segments.splice(idx, 1);
};

const createCampaign = () => {
  if (!isNameFilled.value || !isSegmentsFilled(campaign.value)) {
    isInvalidForm.value = true;
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Проверьте, что все необходимые поля заполнены и наименования бренда и магазина не содержат нижнее подчеркивание.`
    );
    return;
  } else if (isNotValidPeriod(campaign.value)) {
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Дата начала периода не может быть больше даты окончания.`
    );
    return;
  }
  campaign.value.name = campaignName.value;
  const campaignForCreation = prepareModelToServer(campaign.value);
  if (campaignForCreation) {
    emit("setLoading", true);
    if (isCampaignSetted.value) {
      performCampaignAction(() =>
        store.updateCampaign(campaignForCreation, parseInt(route.params.id))
      );
    } else {
      performCampaignAction(() => store.createCampaign(campaignForCreation));
    }
  }
};

const performCampaignAction = (action: any) => {
  const handleCampaignSuccess = () => {
    router.push({ name: "listCampaigns" });
    setTimeout(() => {
      store.activeFirst = false;
    }, 5000);
  };

  const handleCampaignError = (err: any) => {
    if (err.response && err.response.status == 409)
      store.getCampaignByName(campaign.value.name).then((id) => {
        if (id) {
          duplicatedId.value = id;
          confirmVisible.value.duplicate = true;
        }
      });

    if (err.response && err.response.status == 403)
      rootGetters.toast.show("warn", "Ошибка", "Нет доступа.");
  };

  action()
    .then(handleCampaignSuccess)
    .catch(handleCampaignError)
    .finally(() => {
      emit("setLoading", false);
    });
};

const getMessage = (isNameFilled: boolean) => {
  if (isNameFilled) return "Наименование рекламной кампании корректно";
  return "Наименование рекламной кампании некорректно";
};

defineExpose({
  createCampaign,
});
</script>

<style lang="scss" scoped>
.form-filter {
  max-width: 100%;
  width: 100%;
  padding-bottom: 40px;
  //   min-width: 780px;
}

.form-block {
  transition: all 3s ease-in;
}

.form-block.hidden .group-button-container {
  visibility: hidden;
}

.form-block.hidden:hover .group-button-container {
  visibility: visible;
}

.form-block-title {
  margin-bottom: 12px;
}

.some-inputs {
  max-width: 100%;
}

.input-container {
  max-width: calc(50% - 4px);
  width: calc(50% - 4px);
}

.small-label {
  color: #a19cab;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.43rem;
  padding-bottom: 4px;
  padding-left: 4px;
}

label.locked {
  color: #a19cab;
  cursor: default;
}

:deep(.p-inputtext.p-component) {
  width: 100%;
}

:deep(.p-inputtext:disabled),
:deep(.p-dropdown.p-disabled),
:deep(.p-multiselect.p-disabled) {
  background-color: $page-background-color;
}

.p-inputtext.campaign-name:disabled {
  background-color: white;
  color: $main-font-color;
}

.group-button-container {
  justify-content: flex-end;
  margin-top: 12px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s, outline-color 0.2s;

  .delete-button {
    background-color: #fff;
    padding: 8px 8px;
    border-radius: 6px;
    margin-right: 12px;
    cursor: pointer;

    &:hover {
      background-color: #fafaff;
    }
  }

  .group-button {
    background-color: #dbdaf9;
    border-radius: 5px;
    padding: 7px 14px;
    cursor: pointer;

    &:hover {
      background-color: #d4d3f8;

      outline-color: transparent;
    }
  }
}

.p-icon-field-right > .p-input-icon:last-of-type.violete {
  color: #6b69e8;
}

.p-icon-field-right > .p-input-icon:last-of-type.red {
  color: #cd4364;
}
</style>
