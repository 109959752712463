import { defineStore } from "pinia";
import { rootGetters } from "@/main";

import { moveItemToFront } from "@/services/utils/Arrays"

const endpoints = {
    createEstimationSettings: { method: "post", url: "/client_reports_settings" },
    getEstimationSettings: { method: "get", url: "/client_reports_settings" },
    updateEstimationSettingsValue: {
        method: "patch", url: "/client_reports_settings/{setting_id}/value"
    },
    updateBrandStrategy: { method: "put", url: "/client_reports_settings/brand-strategy" },
    deleteBrandStrategy: { method: "delete", url: "/client_reports_settings/brand-strategy" }
};

export const useEstimationsSettingsStore = defineStore("estimations_settings", {
    state: () => ({
        estimationSettingsList: [] as SettingsFromServer[],
        estimationsCount: 0,
        duplicateEstimationSettingId: 0,
        estimationsPerPage: 1,
        activeFirst: false,
        addedEstimationId: 0,
    }),
    getters: {
        estimations: (state) => state.estimationSettingsList,
        estimationsPagesCount: (state) => {
            return Math.ceil(state.estimationsCount / state.estimationsPerPage)
        }
    },
    actions: {
        async updateEstimationSettingsValue(settingId: number, value: number) {
            return await rootGetters.api
                .proceedRequest(endpoints.updateEstimationSettingsValue, { setting_id: settingId }, {}, { value: value })
                .then((response) => {
                    return response.data;
                });
        },
        async updateBrandStrategy(brandStrategy: BrandStrategy) {
            return await rootGetters.api
                .proceedRequest(endpoints.updateBrandStrategy, {}, {}, brandStrategy)
                .then((response) => {
                    return response.data;
                });
        },
        async getEstimationSettings(params: EstimationSettingsParams = { page: 1, per_page: 20 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getEstimationSettings, {}, params
            ).then((response) => {
                this.estimationSettingsList = this.addMapToList(response.data);
                if (this.estimationSettingsList) {
                    this.estimationsPerPage = parseInt(response.headers["x-per-page"]);
                    this.estimationsCount = parseInt(response.headers["x-total-count"]);

                    if (this.addedEstimationId > 0) {
                        moveItemToFront(this.estimationSettingsList, this.addedEstimationId);
                        this.addedEstimationId = 0;
                    }
                }
            });
        },
        async createEstimationSettings(setting: ServerSettingToCreate) {
            await rootGetters.api
                .proceedRequest(endpoints.createEstimationSettings, {}, {}, setting)
                .then((response) => {
                    if (response) {
                        this.addedEstimationId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Создание",
                            `Настройки параметров эстимации добавлены. Вы можете отредактировать значения в строках.`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        addMapToList(data: SettingsFromServer[]) {
            data.forEach(item => {
                item.brand = { brand_name: item.brand_name, brand_pk: item.brand_pk }
                item.mapSettings = item.settings.reduce((acc, setting) => {
                    acc[setting.client_reports_params_id.toString()] = {
                        id: setting.id,
                        value: setting.value
                    };
                    return acc;
                }, {} as { [key: string]: { id: number; value: number } });
            });
            return data;
        },
        async deleteEstimationSettings(brand_pk: string, strategy: string) {
            await rootGetters.api
                .proceedRequest(endpoints.deleteBrandStrategy, {}, { brand_pk: brand_pk, strategy: strategy })
        },


    },
});

interface ServerSettingToCreate {
    brand_pk: string,
    strategy: string
}

export interface SettingsFromServer {
    brand?: Brand;
    brand_pk: string,
    brand_name: string,
    strategy: string,
    settings: ClientReportSetting[]
    mapSettings?: { [key: number]: { id: number; value: number } };
}

interface ClientReportSetting {
    client_reports_params_id: number,
    value: number,
    id: number
}

interface Brand {
    brand_pk: string;
    brand_name: string;
}

interface BrandStrategy {
    old_brand_pk: string,
    old_strategy: string,
    new_brand_pk: string,
    new_strategy: string
}

export interface EstimationSettingsParams {
    page: number;
    per_page: number;

    name?: string;
    name__ilike?: string;
    strategy__ilike?: string;

    cdate?: string;
    mdate?: string;
    cuser__id__in?: string;
    brand_pk__in?: string;

    order_by?: string;
}