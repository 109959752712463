<template>
  <DataTable
    :value="estimationsSettings"
    :class="[
      estimationsSettings && estimationsSettings.length == 0
        ? 'empty-table'
        : '',
      isActiveFirst ? 'active-first' : '',
    ]"
    class="scroll-table-y main-table scrollbar-custom"
    stripedRows
    @sort="sortTable"
    editMode="cell"
    removableSort
    scrollable
    :loading="isLoading"
    dataKey="id"
    :pt="{
      column: {
        bodycell: ({ state }) => ({
          class: [{ 'pt-0 pb-0': state['d_editing'] }],
        }),
      },
    }"
    @cell-edit-complete="onCellEditComplete"
  >
    <Column field="number" header="№" style="min-width: 44px">
      <template #body="slotProps">
        {{ rowsCount + slotProps.index + 1 }}
      </template>
    </Column>
    <Column field="brand" header="Бренд" sortable>
      <template #body="{ data }">
        <span class="table-chip">
          {{ data["brand_name"] }}
        </span>
      </template>
      <template #editor="{ data }">
        <CatalogDataSingleInput
          selectType="brand"
          :use-id-only="false"
          :isSegmentSetted="false"
          :modelValue="data['brand']"
          @update:modelValue="($event) => (data['brand'] = $event)"
        ></CatalogDataSingleInput>
      </template>
    </Column>

    <Column field="strategy" header="Стратегия" sortable>
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" autofocus />
      </template>
    </Column>
    <Column
      v-for="col of paramsStore.itemList"
      :key="col.id"
      :field="col.id.toString()"
    >
      <template #header>
        <span class="p-r-5">{{ col.name }}</span>
        <span
          class="pi pi-info-circle icon-right p-t-2"
          v-tooltip="{
            value: col.description,
            class: 'white-tooltip',
          }"
        ></span>
      </template>
      <template #body="{ data, field }">
        <span v-if="field in data['mapSettings']">{{
          data["mapSettings"][field]["value"]
        }}</span>
      </template>
      <template #editor="{ data, field }">
        <InputText v-model="data['mapSettings'][field]['value']" autofocus />
      </template>
    </Column>
    <Column frozen alignFrozen="right">
      <template #body="slotProps">
        <div
          @click="(event) => toggle(slotProps.data, event)"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          class="button-menu"
        >
          <span :data-segment="1" class="pi pi-ellipsis-v"></span>
        </div>
      </template>
    </Column>
  </DataTable>
  <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
  <DeleteConfirmDialog
    deleted-name="настройки эстимации"
    :visible="confirmVisibleDelete"
    :blocked="blockedButtonDelete"
    @close="confirmVisibleDelete = false"
    @delete="deleteEstimation"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useEstimationParamsDictionaryStore } from "@/store/catalog_data/estimations_params";
import { useEstimationsSettingsStore } from "@/store/analytics/estimation_settings";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Menu from "primevue/menu";
import InputText from "primevue/inputtext";
import DeleteConfirmDialog from "@/shared/dialogs/DeleteConfirmDialog.vue";
import CatalogDataSingleInput from "@/widgets/forms/CatalogDataSingleInput.vue";

import { rootGetters } from "@/main";
import { SettingsFromServer } from "@/store/analytics/estimation_settings";

defineProps<{
  estimationsSettings: SettingsFromServer[];
  isActiveFirst: boolean;
  isLoading: boolean;
  rowsCount: number;
}>();
const emits = defineEmits(["sortTable", "updateTable"]);
const store = useEstimationsSettingsStore();
const paramsStore = useEstimationParamsDictionaryStore();

let modifiedId = ref({ brand_pk: "", strategy: "" });
const confirmVisibleDelete = ref(false);
const blockedButtonDelete = ref(false);
const menu = ref();
const items = ref([
  {
    label: "Удалить",
    icon: "pi pi-trash",
    archive: "both",
    command: () => {
      confirmVisibleDelete.value = true;
    },
  },
]);

const loadData = () => {
  paramsStore.getItems();
};

onMounted(() => {
  loadData();
});

const toggle = (elem: any, event: any) => {
  modifiedId.value.brand_pk = elem.brand_pk;
  modifiedId.value.strategy = elem.strategy;
  menu.value.toggle(event);
};

const sortTable = (event: any) => {
  let orderField = "";
  if (event.sortField) {
    if (event.sortField == "brand")
      orderField = event.sortOrder > 0 ? "brand_pk" : `-brand_pk`;
    else
      orderField =
        event.sortOrder > 0 ? event.sortField : `-${event.sortField}`;
  }

  emits("sortTable", orderField);
};

const deleteEstimation = () => {
  blockedButtonDelete.value = true;
  store
    .deleteEstimationSettings(
      modifiedId.value.brand_pk,
      modifiedId.value.strategy
    )
    .finally(() => {
      emits("updateTable");
      blockedButtonDelete.value = false;
      confirmVisibleDelete.value = false;
    })
    .catch((err) => {
      if (err.response && err.response.status == 400)
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
    });
};

const onCellEditComplete = (event: any) => {
  let { data, newValue, field } = event;

  if (field == "brand") {
    if (newValue["brand_pk"] != data["brand_pk"])
      store
        .updateBrandStrategy({
          new_brand_pk: newValue["brand_pk"],
          old_brand_pk: data["brand_pk"],
          old_strategy: data["strategy"],
          new_strategy: data["strategy"],
        })
        .then(() => {
          data["brand"] = newValue;
          data["brand_name"] = newValue["brand_name"];
          data["brand_pk"] = newValue["brand_pk"];
        })
        .catch((err) => {
          if (err.response && err.response.status == 409)
            rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
        });
  } else if (field == "strategy") {
    if (newValue != data["strategy"])
      store
        .updateBrandStrategy({
          new_brand_pk: data["brand_pk"],
          old_brand_pk: data["brand_pk"],
          old_strategy: data["strategy"],
          new_strategy: newValue,
        })
        .then(() => {
          data["strategy"] = newValue;
        })
        .catch((err) => {
          if (err.response && err.response.status == 409)
            rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
        });
  } else {
    const value = data["mapSettings"][field]["value"]
      .toString()
      .replace(/,/g, ".");
    store
      .updateEstimationSettingsValue(
        data["mapSettings"][field]["id"],
        parseFloat(value)
      )
      .then((dataValue) => {
        if (dataValue.value.toString() != value) {
          rootGetters.toast.show(
            "warn",
            "Ошибка",
            "Неверный формат ввода числа"
          );
        }
        data["mapSettings"][field]["value"] = dataValue.value.toString();
      })
      .catch((err) => {
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
      });
  }
};
</script>

<style lang="scss" scoped>
.table-chip {
  background-color: #eaeaf0;
  padding: 4px;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 13px;
}
</style>
