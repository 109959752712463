import { createStoreConfig } from "./common";

const endpoints = {
    getItems: { method: "get", url: "/organizations/base" },
};

export const useOrganizationsBaseDictionaryStore = createStoreConfig<Organization>(endpoints, "organizations_base_dictionarie", {
    order_by: "name",
    in: "id__in",
    pk: "id",
    ilike: "name__ilike",
    sortOptions: { primarySortField: "name" }
})

export interface Organization {
    id: number;
    description: string;
    tenant: string;
    name: string;
    is_inhouse: boolean;
}